import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpClientModule } from "@angular/common/http";
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { ScoreWeight } from '../core/models/score-weight';

@Injectable()
export class BuildingScoresService {

	BaseURI_API_Account = environment.BaseURI_API_Account;
	BaseURI_API_Asset = environment.BaseURI_API + 'Asset/';
	BaseURI_API_Score = environment.BaseURI_API_Score;
	BaseURI_YT = environment.BaseURI_APIExtension;

	averageVacancyRating: number;	
	averageRating: number;
	averageVacancyRatingToRentRatio: number;
	averageRatingToRentRatio: number;
	
	subMarket: string;
	district: string;
	class: string;
	tenancy: string;
	status: string;
	leaseType: string;
	inputFilterSearchTerm: string;
	inputcontiguousSFMinValue: number;
	inputcontiguousSFMaxValue: number;
	rangeSFValuesMin: any;
	rangeSFValuesMax: any;
	rangePercentLeasedValuesMin: any;
	rangePercentLeasedValuesMax: any;
	rangeNumberOfFloorMinValues: any;
	rangeNumberOfFloorMaxValues: any;
	rangePropertySizeMin: any;
	rangePropertySizeMax: any;
	inputparkingRatioMinValue: number;
	inputparkingRatioMaxValue: number;
	rangeWalkScoreValuesMin: any;
	rangeWalkScoreValuesMax: any;
	inputMinValueAskingRent: number;
	inputMaxValueAskingRent: number;
	inputLightRailMinValue: number;
	inputLightRailMaxValue: number;
	inputRatingToRentRatioMinValue: number;
	inputRatingToRentRatioMaxValue: number;
	inputavailableSFDirectMinValue: number;
	inputavailableSFDirectMaxValue: number;
	inputavailableSFSLMinValue: number;
	inputavailableSFSLMaxValue: number;
	inputttlVacantSFMinValue: number;
	inputttlVacantSFMaxValue: number;
	inputslVacantSFMinValue: number;
	inputslVacantSFMaxValue: number;
	inputdVacantSFMinValue: number;
	inputdVacantSFMaxValue: number;
	inputminAvailableSFMinValue: number;
	inputminAvailableSFMaxValue: number;
	inputoccupiedSFMinValue: number;
	inputoccupiedSFMaxValue: number;
	subclass: string;
	propertyType: string;
	renovations: string;
	inputyearOfRenovationMinValue: number;
	inputyearOfRenovationMaxValue: number;
	mixedUse: string;
	singleTenant: string;
	parking: string;
	serviceOfficeMarketBuildingList: any = [];
	buildingScoresList: any = [];
	competitiveSet: string;
	deletedPropertyIdFromOfficeBuildingListService: string = "";
	propertyId: string = "0";
	mapTypeIdSelected: string = "";

	private selectedWeightSubject = new BehaviorSubject<ScoreWeight>({
		id: "",
		name: "",
		default: false,
		userId: 0,
		constructionQuality: 0,
		askingRate: 0,
		commonAreaQuality: 0,
		onsiteAmenities: 0,
		sizeStories: 0,
		ageBonus: 0,
		locationProminence: 0,
		relevanceToConsumer: 0,
		locationConvenience: 0,
		locationProximity: 0,
		parkingVisitorExperience: 0,
		parkingQuantity: 0,
		parkingQuality: 0,
		updatedUtc: new Date(),
	});
	selectedWeight$ = this.selectedWeightSubject.asObservable();

	constructor(private http: HttpClient) { }


	SubMarketList() {
		return this.http.get(this.BaseURI_API_Account + 'GetSubMarketList');
	}

	DistrictList() {
		return this.http.get(this.BaseURI_API_Account + 'GetDistrictList');
	}

	BuildingClassList() {
		return this.http.get(this.BaseURI_API_Account + 'GetBuildingClassList');
	}

	BuildingLeaseTypeList() {
		return this.http.get(this.BaseURI_API_Account + 'GetBuildingLeaseTypeList');
	}
	
	BuildingSubclassList() {
		return this.http.get(this.BaseURI_API_Account + 'GetBuildingSubclassList');
	}

	BuildingPropertyTypeList() {
		return this.http.get(this.BaseURI_API_Account + 'GetBuildingPropertyTypeList');
	}

	BuildingRenovationsList() {
		return this.http.get(this.BaseURI_API_Account + 'GetBuildingRenovationsList');
	}

	BuildingMixedUseList() {
		return this.http.get(this.BaseURI_API_Account + 'GetBuildingMixedUseList');
	}

	BuildingSingleTenantList() {
		return this.http.get(this.BaseURI_API_Account + 'GetBuildingSingleTenantList');
	}

	BuildingParkingList() {
		return this.http.get(this.BaseURI_API_Account + 'GetBuildingParkingList');
	}

	BuildingStatusList() {
		return this.http.get(this.BaseURI_API_Account + 'GetBuildingStatusList');
	}

	BuildingTenancyList() {
		return this.http.get(this.BaseURI_API_Account + 'GetBuildingTenancyList');
	}

	GetAllPropertyScores(scoreWeightId: string): any {
		return this.http.get(this.BaseURI_API_Score + 'GetAllPropertyScores/' + scoreWeightId);
	}

	GetPropertyScores(propertyIds: number[], scoreWeightId: string): any {
		return this.http.post(this.BaseURI_API_Score + 'GetPropertyScores/' + scoreWeightId, propertyIds);
	}

	GetBuildingScoresList() {
		return this.http.get(this.BaseURI_YT + 'building-scores/');
	}

	GeneratePdfFromHtml() {
		return this.http.get(this.BaseURI_API_Account + 'GeneratePdfFromHtml');
	}

	RatingsPdfExport(id: string) {
		const propertyIds: number[] = this.buildingScoresList.map(obj => obj.propertyId);
		return this.http.post(this.BaseURI_API_Score + 'BuildingScorePdfExport/' + id, propertyIds, { responseType: 'blob' });
	}

	RatingsExcelExport(id: string) {
		const propertyIds: number[] = this.buildingScoresList.map(obj => obj.propertyId);
		return this.http.post(this.BaseURI_API_Score + 'BuildingScoreExcelExport/' + id, propertyIds, { responseType: 'blob' });
	}

	MarkersDetailbyFilter(FromDate, ToDate, Submarket, District, BuildingClass, BuildingTenancy, BuildingStatus, LeaseType, ContiguousSFMinValue,
			ContiguousSFMaxValue, MinSFValue, MaxSFValue, rangeNumberOfFloorMinValue, rangeNumberOfFloorMaxValue, PropertySizeMinValue, PropertySizeMaxValue, ParkingRatioMinValue,
			ParkingRatioMaxValue, WalkScoreMinValue, WalkScoreMaxValue, DirLsRateYrMinValue, DirLsRateYrMaxValue, IsPdfGenerate, IsCSVGenerate, LightRailMinValue,
			LightRailMaxValue, competitiveSet, propertyIdList, FromDateString, ToDateString)
	{
		var thebody = {
			FromDate: FromDate,
			ToDate: ToDate,
			Submarket: Submarket,
			District: District,
			Class: BuildingClass,
			Tenancy: BuildingTenancy,
			PropStatus: BuildingStatus,
			LeaseType: LeaseType,
			MinContigSF: ContiguousSFMinValue,
			MaxContigSFValue: ContiguousSFMaxValue,
			MinSFValue: MinSFValue,
			MaxSFValue: MaxSFValue,
			NumberOfFloorMinValue: rangeNumberOfFloorMinValue,
			NumberOfFloorMaxValue: rangeNumberOfFloorMaxValue,
			PropertySizeMinValue: PropertySizeMinValue,
			PropertySizeMaxValue: PropertySizeMaxValue,
			ParkingRatioMinValue: ParkingRatioMinValue,
			ParkingRatioMaxValue: ParkingRatioMaxValue,
			WalkScoreMinValue: WalkScoreMinValue,
			WalkScoreMaxValue: WalkScoreMaxValue,
			DirLsRateYrMinValue: DirLsRateYrMinValue,
			DirLsRateYrMaxValue: DirLsRateYrMaxValue,
			IsPdfGenerate: IsPdfGenerate,
			IsCSVGenerate: IsCSVGenerate,
			LightRailsMinValue: LightRailMinValue,
			LightRailsMaxValue: LightRailMaxValue,
			CompetitiveSet: competitiveSet,
			PropertyIdList: propertyIdList,
			FromDateString: FromDateString,
			ToDateString: ToDateString
		};

		return this.http.post(this.BaseURI_YT + 'GetMarkersDetails', {thebody:thebody});
	}

	MarkersDetailbyFilter_Scores(Submarket, District, BuildingClass, BuildingTenancy, BuildingStatus, LeaseType,ConstructionQuality_Min, ConstructionQuality_Max, AskingRate_Min, AskingRate_Max, CommonAreaQuality_Min, CommonAreaQuality_Max, OnsiteAmenities_Min, OnsiteAmenities_Max, SizeStories_Min, SizeStories_Max, AgeBonus_Min, AgeBonus_Max,
LocationProminence_Min, LocationProminence_Max, RelevanceToConsumer_Min, RelevanceToConsumer_Max, LocationConvenience_Min, LocationConvenience_Max, LocationProximity_Min, LocationProximity_Max,
Walkscore_Min, Walkscore_Max, ParkingVisitorExperience_Min, ParkingVisitorExperience_Max, ParkingQuantity_Min, ParkingQuantity_Max, ParkingQuality_Min, ParkingQuality_Max, subfilterOptions=null)
	{
		console.log('subfilterOptions:',subfilterOptions);
		var body = {
			Submarket: Submarket,
			District: District,
			Class: BuildingClass,
			Tenancy: BuildingTenancy,
			PropStatus: BuildingStatus,
			LeaseType: LeaseType,

			ConstructionQualityMin: ConstructionQuality_Min,
			ConstructionQualityMax: ConstructionQuality_Max,
			AskingRateMin: AskingRate_Min,
			AskingRateMax: AskingRate_Max,
			CommonAreaQualityMin: CommonAreaQuality_Min,
			CommonAreaQualityMax: CommonAreaQuality_Max,
			OnsiteAmenitiesMin: OnsiteAmenities_Min,
			OnsiteAmenitiesMax: OnsiteAmenities_Max,
			SizeStoriesMin: SizeStories_Min,
			SizeStoriesMax: SizeStories_Max,
			AgeBonusMin: AgeBonus_Min,
			AgeBonusMax: AgeBonus_Max,

			LocationProminenceMin: LocationProminence_Min,
			LocationProminenceMax: LocationProminence_Max,
			RelevanceToConsumerMin: RelevanceToConsumer_Min,
			RelevanceToConsumerMax: RelevanceToConsumer_Max,
			LocationConvenienceMin: LocationConvenience_Min,
			LocationConvenienceMax: LocationConvenience_Max,
			LocationProximityMin: LocationProximity_Min,
			LocationProximityMax: LocationProximity_Max,

			WalkscoreMin: Walkscore_Min,
			WalkscoreMax: Walkscore_Max,
			ParkingVisitorExperienceMin: ParkingVisitorExperience_Min,
			ParkingVisitorExperienceMax: ParkingVisitorExperience_Max,
			ParkingQuantityMin: ParkingQuantity_Min,
			ParkingQuantityMax: ParkingQuantity_Max,
			ParkingQualityMin: ParkingQuality_Min,
			ParkingQualityMax: ParkingQuality_Max,
			subfilterOptions:subfilterOptions
		};

		return this.http.post(this.BaseURI_YT + 'building-scores/filter', body);
	}

	GeneratePdfForMarkersDetails(FromDate, ToDate, Submarket, District, BuildingClass, BuildingTenancy, BuildingStatus, LeaseType, ContiguousSFMinValue,
		ContiguousSFMaxValue, MinSFValue, MaxSFValue, rangeNumberOfFloorMinValue, rangeNumberOfFloorMaxValue, PropertySizeMinValue, PropertySizeMaxValue, ParkingRatioMinValue,
		ParkingRatioMaxValue, WalkScoreMinValue, WalkScoreMaxValue, DirLsRateYrMinValue, DirLsRateYrMaxValue, IsPdfGenerate, IsCSVGenerate, LightRailMinValue,
		LightRailMaxValue, competitiveSet, propertyIdList, propertyId, mapTypeIdSelected)
	{
		var body = {
			FromDate: FromDate,
			ToDate: ToDate,
			Submarket: Submarket,
			District: District,
			Class: BuildingClass,
			Tenancy: BuildingTenancy,
			PropStatus: BuildingStatus,
			LeaseType: LeaseType,
			MinContigSF: ContiguousSFMinValue,
			MaxContigSFValue: ContiguousSFMaxValue,
			MinSFValue: MinSFValue,
			MaxSFValue: MaxSFValue,
			NumberOfFloorMinValue: rangeNumberOfFloorMinValue,
			NumberOfFloorMaxValue: rangeNumberOfFloorMaxValue,
			PropertySizeMinValue: PropertySizeMinValue,
			PropertySizeMaxValue: PropertySizeMaxValue,
			ParkingRatioMinValue: ParkingRatioMinValue,
			ParkingRatioMaxValue: ParkingRatioMaxValue,
			WalkScoreMinValue: WalkScoreMinValue,
			WalkScoreMaxValue: WalkScoreMaxValue,
			DirLsRateYrMinValue: DirLsRateYrMinValue,
			DirLsRateYrMaxValue: DirLsRateYrMaxValue,
			IsPdfGenerate: IsPdfGenerate,
			IsCSVGenerate: IsCSVGenerate,
			LightRailsMinValue: LightRailMinValue,
			LightRailsMaxValue: LightRailMaxValue,
			CompetitiveSet: competitiveSet,
			PropertyIdList: propertyIdList,
			PropertyId: propertyId,
			MapTypeId:mapTypeIdSelected
		};
		return this.http.post(this.BaseURI_API_Account + 'GeneratePdfForMarkersDetails', body);
	}
	BuildingSquareFootageMinMaxValue() {
		return this.http.get(this.BaseURI_API_Account + 'BuildingSquareFootageMinMaxValue');
	}
	BuildingNRAPropertySizeMinMaxValue() {
		return this.http.get(this.BaseURI_API_Account + 'BuildingNRAPropertySizeMinMaxValue');
	}
	CalculateAbsorptionForOccupiedSF(PropertyId, FromDate, ToDate, Submarket, District, BuildingClass, BuildingTenancy, BuildingStatus,
		LeaseType, ContiguousSFMinValue, ContiguousSFMaxValue, MinSFValue, MaxSFValue, rangeNumberOfFloorMinValue,
		rangeNumberOfFloorMaxValue, PropertySizeMinValue, PropertySizeMaxValue, ParkingRatioMinValue,
			ParkingRatioMaxValue, WalkScoreMinValue, WalkScoreMaxValue, DirLsRateYrMinValue, DirLsRateYrMaxValue, QuarterAndQuarterYear, selectedToQuarter, competitiveSet) {
		var body = {
			FromDate: FromDate,
			ToDate: ToDate,
			Submarket: Submarket,
			District: District,
			Class: BuildingClass,
			Tenancy: BuildingTenancy,
			PropStatus: BuildingStatus,
			LeaseType: LeaseType,
			MinContigSF: ContiguousSFMinValue,
			MaxContigSFValue: ContiguousSFMaxValue,
			MinSFValue: MinSFValue,
			MaxSFValue: MaxSFValue,
			NumberOfFloorMinValue: rangeNumberOfFloorMinValue,
			NumberOfFloorMaxValue: rangeNumberOfFloorMaxValue,
			PropertySizeMinValue: PropertySizeMinValue,
			PropertySizeMaxValue: PropertySizeMaxValue,
			ParkingRatioMinValue: ParkingRatioMinValue,
			ParkingRatioMaxValue: ParkingRatioMaxValue,
			WalkScoreMinValue: WalkScoreMinValue,
			WalkScoreMaxValue: WalkScoreMaxValue,
			DirLsRateYrMinValue: DirLsRateYrMinValue,
			DirLsRateYrMaxValue: DirLsRateYrMaxValue,
			QuarterAndQuarterYear: QuarterAndQuarterYear,
				PropertyIdSelect: PropertyId,
				QuarterAndQuarterToYear: selectedToQuarter,
				CompetitiveSet: competitiveSet

		};
			return this.http.post(this.BaseURI_API_Account + 'CalculateAbsorptionForOccupiedSF', body);
		 // return this.http.post(this.BaseURI + 'NewCalculateAbsorptionForOccupiedSF', body);
	}

	DownloadFile(fileName): any {
		return this.http.get(this.BaseURI_API_Account+"DownloadFile?fileName="+ fileName, {
			responseType: 'blob',
			observe: 'response'
		})
		.pipe(
			map((res: any) => {
				return new Blob([res.body], { type: 'application/pdf' });
			})
		);
	}

	DownloadCSVFile(fileName): any {
		return this.http.get(this.BaseURI_API_Account + "downloadCSVFile?fileName=" + fileName, {
			responseType: 'blob',
			observe: 'response'
		})
			.pipe(
				map((res: any) => {
					return new Blob([res.body], { type: 'application/vnd.ms-excel' });
				})
			);
		}

	CompetitiveSetList(userId) {
		return this.http.get(this.BaseURI_API_Account + 'CompetativeSetList?userId=' + userId);
		}

	GetMarkersDetailsByPropertyId(propertyId) {

		return this.http.get(this.BaseURI_YT + 'building-scores/' + propertyId);
	}

	UpdateBuildingScores(scores: any, scoreWeightId: string) {
		return this.http.post(this.BaseURI_API_Score + 'UpdatePropertyScores/' + scoreWeightId, scores);
	}

	CalculateStats(properties: any, scoreWeightId: string) {
		var buildings = properties;
		var scores: any;

		this.GetPropertyScores(buildings.map(x => x.propertyId), scoreWeightId).subscribe(
			(res: any) => {
				scores = res;

				buildings.forEach(building => {
					const score = scores.find(s => s.propertyID === building.propertyId);
					if (score) {
						building.totalScore = score.totalScore;
		
						if (building.minDirLsRate_Yr != 0 && building.totalScore != 0) {
							building.ratingToRentRatio = building.totalScore / building.minDirLsRate_Yr;
						}
						else {
							building.ratingToRentRatio = 0;
						}
					} else {
					  building.totalScore = null;
					}
				  });

				  this.averageVacancyRating = this.CalculateAverageVacancyRating(buildings);
				  this.averageRating = this.CalculateAverageRating(buildings);
				  this.averageVacancyRatingToRentRatio = this.CalculateAverageVacancyRatingToRentRatio(buildings);				  
				  this.averageRatingToRentRatio = this.CalculateAverageRatingToRentRatio(buildings);
			},
			err => {
				console.log("Error retrieving scores");
			}
		);
	}

	SetSelectedScoreWeight(weight: ScoreWeight) {
		this.selectedWeightSubject.next(weight);
	}

	GetScoreWeight(id: number) {
		return this.http.get(this.BaseURI_API_Score + 'Weight/' + id);
	}

	GetScoreWeights() {
		return this.http.get(this.BaseURI_API_Score + 'Weights/' + sessionStorage.getItem('id'));
	}

	CreateUpdateScoreWeight(weight: any) {
		return this.http.post(this.BaseURI_API_Score + 'Weight', weight);
	}

	DeleteScoreWeight(id: string) {
		return this.http.delete(this.BaseURI_API_Score + 'Weight/' + id);
	}

	GetAssetBuildingRatingMethodology() {
		return this.http.get(this.BaseURI_API_Asset + 'building-rating-methodology', { responseType: 'text' });
	  }

	/// Formula 1
	CalculateAverageVacancyRating(buildings: any) {
		let weightedSum = 0;
		let totalVacantSF = 0;

		buildings.forEach(building => {
			weightedSum += (building.totalScore * building.ttlVacantSF);
			totalVacantSF += building.ttlVacantSF;
		});

		if (totalVacantSF === 0) {
			return 0;
		}

		return (weightedSum / totalVacantSF);
	}
	
	/// Formula 2
	CalculateAverageRating(buildings: any) {
		let weightedSum = 0;
		let totalNRA = 0;

		buildings.forEach(building => {
			weightedSum += (building.totalScore * building.buildingNRA);
			totalNRA += building.buildingNRA;
		});

		if (totalNRA === 0) {
			return 0;
		}

		return (weightedSum / totalNRA);
	}
	
	/// Formula 3
	CalculateAverageVacancyRatingToRentRatio(buildings: any) {
		let weightedSum = 0;
		let totalVacantSF = 0;

		buildings.forEach(building => {
			weightedSum += (building.ratingToRentRatio * building.ttlVacantSF);
			totalVacantSF += building.ttlVacantSF;
		});

		if (totalVacantSF === 0) {
			return 0;
		}

		return (weightedSum / totalVacantSF);
	}
	
	/// Formula 4
	CalculateAverageRatingToRentRatio(buildings: any) {
		let weightedSum = 0;
		let totalBuildings = buildings.length;

		buildings.forEach(building => {
			weightedSum += building.ratingToRentRatio;
		});

		if (totalBuildings === 0) {
			return 0;
		}

		return (weightedSum / totalBuildings);
	}
}
