import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpClientModule } from "@angular/common/http";
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
@Injectable()

export class AccountService {

  BaseURI_Account = environment.BaseURI_API_Account;
  BaseURI_Asset = environment.BaseURI_API + 'Asset/'

  reportDetails: any;
  constructor(private http: HttpClient) { }

  //this service method to logged user.
  Login(email: string, password: string) {
    //debugger
    return this.http.post(this.BaseURI_Account + 'Authenticate', { email, password });
  }

  ImportExcel() {
    return this.http.post(this.BaseURI_Account + 'UploadExcel', {});
  }

  RequestAccess(Name, Password, Email, CompanyName) {
    return this.http.post(this.BaseURI_Account + 'RequestAccess', { Name, Password, Email,  CompanyName });
  }

  ValidateUser(Email) {
    return this.http.post(this.BaseURI_Account + 'ForgotPassword', { Email});
  }

  ResetPassword(Email, Code, Password) {
    return this.http.post(this.BaseURI_Account + 'ResetPassword', { Email,Code,Password });
  }
  InsertReportDetails(title, description) {
    var body = {
      Title: title,
      Description: description
    }
    return this.http.post(this.BaseURI_Account + 'InsertReportDetails', body);
  }
  GetReportDetails() {
    return this.http.get(this.BaseURI_Account + 'GetReportDetails');
  }


  DownloadUploadeReports(fileName): any {
    return this.http.get(this.BaseURI_Account + "DownloadUploadeReports?fileName=" + fileName, {
      responseType: 'blob',
      observe: 'response'
    })
      .pipe(
        map((res: any) => {
          return new Blob([res.body], { type: 'application/pdf' });
        })
      );
  }

  DeleteUploadedReportByReportID(reportId) {
    
    return this.http.get(this.BaseURI_Account + 'DeleteReportDetailsByReportId?ReportId=' + reportId);
  }
  GetReportDetailsByReportId(reportId) {
    
    return this.http.get(this.BaseURI_Account + 'GetReportDetailsByReportId?ReportId=' + reportId);
  }

  GetAssetTeamPhoto() {
    return this.http.get(this.BaseURI_Asset + 'team-photo', { responseType: 'text' });
  }

}
