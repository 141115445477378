import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FilterPreset } from '../../core/models/filter-preset';
import { FilterService } from '../../services/filter.service';

@Component({
    selector: 'user-filters-modal',
    templateUrl: './user-filters-modal.component.html',
    styleUrls: ['./user-filters-modal.component.css']
})
export class UserFiltersModalComponent {
    @Input() filters: FilterPreset[] = [];
    @Output() deleteItem = new EventEmitter<string>();
    @Output() closeModal = new EventEmitter<void>();

    role = sessionStorage.getItem('role');
    userId = parseInt(sessionStorage.getItem('id'))

    constructor(private filterService: FilterService ) {
	}

    canDelete(filter: FilterPreset) {
        return this.userId == filter.userId;
    }

    onDelete(filterId: string) {        
        this.filterService.DeleteUserFilter(filterId).subscribe(
			(res: FilterPreset[]) => {
				this.deleteItem.emit(filterId);
			},
			err => {
				console.log("Could not retrieve user filters: ", err);
			}
		);
    }

    onClose() {
        this.closeModal.emit();
    }
}