import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-portal-permission-modelpopup',
  templateUrl: './portal-permission-modelpopup.component.html',
  styleUrls: ['./portal-permission-modelpopup.component.css']
})
export class PortalPermissionModelpopupComponent implements OnInit {
  @Input('getShowPortalmodal') getShowPortalmodal;
  @Output('Rmodal') Rmodal = new EventEmitter();
  @Input('bindPortalModal') bindPortalModal;
  @Output('Cmodal') Cmodal = new EventEmitter();
  closeModal: boolean = false;
  BindResult: any;
  constructor() { }

  ngOnInit() {
    this.BindResult = this.bindPortalModal
  }

  CloseModal() {
    this.closeModal = this.getShowPortalmodal;
    this.closeModal = !this.closeModal;
    this.Rmodal.emit(this.closeModal);
  }


  PermissionModel() {
    this.CloseModal();
    this.BindResult = this.bindPortalModal;
    this.Cmodal.emit(this.BindResult);
  }
}
