import {
	Injectable
} from '@angular/core';
import {
	HttpClient,
	HttpHeaders,
	HttpClientModule
} from "@angular/common/http";
import {
	map
} from 'rxjs/operators';
import {
	environment
} from '../../environments/environment';
import { ScoreWeight } from '../core/models/score-weight';

@Injectable()
export class MarketBuildingService {

	BaseURI_Absorption = environment.BaseURI_API_Absorption;
	BaseURI_Account = environment.BaseURI_API_Account;
	BaseURI_Market = environment.BaseURI_API_Market;

	myFromDate: string;
	myToDate: string;
	subMarket: string;
	district: string;
	class: string;
	tenancy: string;
	status: string;
	leaseType: string;
	inputFilterSearchTerm: string;
	inputcontiguousSFMinValue: number;
	inputcontiguousSFMaxValue: number;
	rangeSFValuesMin: any;
	rangeSFValuesMax: any;
	rangePercentLeasedValuesMin: any;
	rangePercentLeasedValuesMax: any;
	rangeNumberOfFloorMinValues: any;
	rangeNumberOfFloorMaxValues: any;
	rangePropertySizeMin: any;
	rangePropertySizeMax: any;
	inputparkingRatioMinValue: number;
	inputparkingRatioMaxValue: number;
	rangeWalkScoreValuesMin: any;
	rangeWalkScoreValuesMax: any;
	inputMinValueAskingRent: number;
	inputMaxValueAskingRent: number;
	inputLightRailMinValue: number;
	inputLightRailMaxValue: number;
	inputRatingToRentRatioMinValue: number;
	inputRatingToRentRatioMaxValue: number;
	inputavailableSFDirectMinValue: number;
	inputavailableSFDirectMaxValue: number;
	inputavailableSFSLMinValue: number;
	inputavailableSFSLMaxValue: number;
	inputttlVacantSFMinValue: number;
	inputttlVacantSFMaxValue: number;
	inputslVacantSFMinValue: number;
	inputslVacantSFMaxValue: number;
	inputdVacantSFMinValue: number;
	inputdVacantSFMaxValue: number;
	inputminAvailableSFMinValue: number;
	inputminAvailableSFMaxValue: number;
	inputoccupiedSFMinValue: number;
	inputoccupiedSFMaxValue: number;
	subclass: string;
	propertyType: string;
	renovations: string;
	inputyearOfRenovationMinValue: number;
	inputyearOfRenovationMaxValue: number;
	mixedUse: string;
	singleTenant: string;
	parking: string;

	// officeMarketBuildingList is the displayed building list
	officeMarketBuildingList: any = [];

	// officeMarketBuildingDeletedList is the removed building list
	// this is maintained separately so you can remove/show all buildings on the right list
	officeMarketBuildingDeletedList: any = [];

	competitiveSet: string;
	propertyId: string = "0";
	mapTypeIdSelected: string = "";
	
	public scoreWeight: ScoreWeight = {
		id: "",
		name: "",
		default: false,
		userId: 0,
		constructionQuality: 0,
		askingRate: 0,
		commonAreaQuality: 0,
		onsiteAmenities: 0,
		sizeStories: 0,
		ageBonus: 0,
		locationProminence: 0,
		relevanceToConsumer: 0,
		locationConvenience: 0,
		locationProximity: 0,
		parkingVisitorExperience: 0,
		parkingQuantity: 0,
		parkingQuality: 0,
		updatedUtc: new Date(),
	};

	constructor(private http: HttpClient) {}


	SubMarketList() {
		return this.http.get(this.BaseURI_Account + 'GetSubMarketList');
	}

	DistrictList() {
		return this.http.get(this.BaseURI_Account + 'GetDistrictList');
	}

	BuildingClassList() {
		return this.http.get(this.BaseURI_Account + 'GetBuildingClassList');
	}

	BuildingLeaseTypeList() {
		return this.http.get(this.BaseURI_Account + 'GetBuildingLeaseTypeList');
	}

	BuildingSubclassList() {
		return this.http.get(this.BaseURI_Account + 'GetBuildingSubclassList');
	}

	BuildingPropertyTypeList() {
		return this.http.get(this.BaseURI_Account + 'GetBuildingPropertyTypeList');
	}

	BuildingRenovationsList() {
		return this.http.get(this.BaseURI_Account + 'GetBuildingRenovationsList');
	}

	BuildingMixedUseList() {
		return this.http.get(this.BaseURI_Account + 'GetBuildingMixedUseList');
	}

	BuildingSingleTenantList() {
		return this.http.get(this.BaseURI_Account + 'GetBuildingSingleTenantList');
	}

	BuildingParkingList() {
		return this.http.get(this.BaseURI_Account + 'GetBuildingParkingList');
	}

	BuildingStatusList() {
		return this.http.get(this.BaseURI_Account + 'GetBuildingStatusList');
	}

	BuildingTenancyList() {
		return this.http.get(this.BaseURI_Account + 'GetBuildingTenancyList');
	}

	MarkersDetailbyFilter() {
		var body = {
			SearchTerm: this.inputFilterSearchTerm,
			FromDate: this.myFromDate,
			ToDate: this.myToDate,
			Submarket: this.subMarket,
			District: this.district,
			Class: this.class,
			Tenancy: this.tenancy,
			PropStatus: this.status,
			LeaseType: this.leaseType,
			MinContigSF: this.inputcontiguousSFMinValue,
			MaxContigSFValue: this.inputcontiguousSFMaxValue,
			MinSFValue: this.inputminAvailableSFMinValue,
			MaxSFValue: this.inputminAvailableSFMaxValue,
			NumberOfFloorMinValue: this.rangeNumberOfFloorMinValues,
			NumberOfFloorMaxValue: this.rangeNumberOfFloorMaxValues,
			PropertySizeMinValue: this.rangePropertySizeMin,
			PropertySizeMaxValue: this.rangePropertySizeMax,
			ParkingRatioMinValue: this.inputparkingRatioMinValue,
			ParkingRatioMaxValue: this.inputparkingRatioMaxValue,
			WalkScoreMinValue: this.rangeWalkScoreValuesMin,
			WalkScoreMaxValue: this.rangeWalkScoreValuesMax,
			DirLsRateYrMinValue: this.inputMinValueAskingRent,
			DirLsRateYrMaxValue: this.inputMaxValueAskingRent,
			IsPdfGenerate: false,
			IsCSVGenerate: false,
			LightRailsMinValue: this.inputLightRailMinValue,
			LightRailsMaxValue: this.inputLightRailMaxValue,
			CompetitiveSet: this.competitiveSet,
			FromDateString: this.myFromDate,
			ToDateString: this.myToDate,
			MinPercentLeasedValues: this.rangePercentLeasedValuesMin,
			MaxPercentLeasedValues: this.rangePercentLeasedValuesMax,
			AvailableSFDirectMinValue: this.inputavailableSFDirectMinValue,
			AvailableSFDirectMaxValue: this.inputavailableSFDirectMaxValue,
			AvailableSFSLMinValue: this.inputavailableSFSLMinValue,
			AvailableSFSLMaxValue: this.inputavailableSFSLMaxValue,
			TtlVacantSFMinValue: this.inputttlVacantSFMinValue,
			TtlVacantSFMaxValue: this.inputttlVacantSFMaxValue,
			DVacantSFMinValue: this.inputdVacantSFMinValue,
			DVacantSFMaxValue: this.inputdVacantSFMaxValue,
			SlVacantSFMinValue: this.inputslVacantSFMinValue,
			SlVacantSFMaxValue: this.inputslVacantSFMaxValue,
			MinAvailableSFMinValue: this.inputminAvailableSFMinValue,
			MinAvailableSFMaxValue: this.inputminAvailableSFMaxValue,
			OccupiedSFMinValue: this.inputoccupiedSFMinValue,
			OccupiedSFMaxValue: this.inputoccupiedSFMaxValue,
			Subclass: this.subclass,
			PropertyType: this.propertyType,
			Renovations: this.renovations,
			YearOfRenovationMinValue: this.inputyearOfRenovationMinValue,
			YearOfRenovationMaxValue: this.inputyearOfRenovationMaxValue,
			MixedUse: this.mixedUse,
			SingleTenant: this.singleTenant,
			Parking: this.parking,
			RatingToRentRatioMinValue: this.inputRatingToRentRatioMinValue,
			RatingToRentRatioMaxValue: this.inputRatingToRentRatioMaxValue,
			ScoreWeightId: this.scoreWeight.id
		};

		return this.http.post(this.BaseURI_Market + 'GetMarketBuildingsByFilter', body);
	}

	GeneratePdfForMarkersDetails(FromDate, ToDate, Submarket, District, BuildingClass, BuildingTenancy, BuildingStatus, LeaseType, ContiguousSFMinValue,
		ContiguousSFMaxValue, MinSFValue, MaxSFValue, rangeNumberOfFloorMinValue, rangeNumberOfFloorMaxValue, PropertySizeMinValue, PropertySizeMaxValue, ParkingRatioMinValue,
		ParkingRatioMaxValue, WalkScoreMinValue, WalkScoreMaxValue, DirLsRateYrMinValue, DirLsRateYrMaxValue, IsPdfGenerate, IsCSVGenerate, LightRailMinValue,
		LightRailMaxValue, competitiveSet, propertyIdList, propertyId, mapTypeIdSelected) {
		var body = {
			FromDate: FromDate,
			ToDate: ToDate,
			Submarket: Submarket,
			District: District,
			Class: BuildingClass,
			Tenancy: BuildingTenancy,
			PropStatus: BuildingStatus,
			LeaseType: LeaseType,
			MinContigSF: ContiguousSFMinValue,
			MaxContigSFValue: ContiguousSFMaxValue,
			MinSFValue: MinSFValue,
			MaxSFValue: MaxSFValue,
			NumberOfFloorMinValue: rangeNumberOfFloorMinValue,
			NumberOfFloorMaxValue: rangeNumberOfFloorMaxValue,
			PropertySizeMinValue: PropertySizeMinValue,
			PropertySizeMaxValue: PropertySizeMaxValue,
			ParkingRatioMinValue: ParkingRatioMinValue,
			ParkingRatioMaxValue: ParkingRatioMaxValue,
			WalkScoreMinValue: WalkScoreMinValue,
			WalkScoreMaxValue: WalkScoreMaxValue,
			DirLsRateYrMinValue: DirLsRateYrMinValue,
			DirLsRateYrMaxValue: DirLsRateYrMaxValue,
			IsPdfGenerate: IsPdfGenerate,
			IsCSVGenerate: IsCSVGenerate,
			LightRailsMinValue: LightRailMinValue,
			LightRailsMaxValue: LightRailMaxValue,
			CompetitiveSet: competitiveSet,
			PropertyIdList: propertyIdList,
			PropertyId: propertyId,
			MapTypeId: mapTypeIdSelected
		};
		return this.http.post(this.BaseURI_Account + 'GeneratePdfForMarkersDetails', body);
	}
	CompetitiveSetPdfExport() {
		const ids: number[] = this.officeMarketBuildingList.map(obj => obj.id);
		return this.http.post(this.BaseURI_Market + 'CompetitiveSetPdfExport', ids, { responseType: 'blob' });
	}
	MarketExcelExport() {
		const ids: number[] = this.officeMarketBuildingList.map(obj => obj.id);
		return this.http.post(this.BaseURI_Market + 'MarketExcelExport', ids, { responseType: 'blob' });
	}
	BuildingSquareFootageMinMaxValue() {
		return this.http.get(this.BaseURI_Account + 'BuildingSquareFootageMinMaxValue');
	}
	GetAbsorptionQuarters() {
		return this.http.get(this.BaseURI_Absorption + 'GetAbsorptionQuarters');
	}
	CalculateAbsorption(quarterStart: string, quarterEnd: string) {
		const ids: number[] = this.officeMarketBuildingList.map(obj => obj.propertyId);
		return this.http.post(this.BaseURI_Absorption + 'CalculateAbsorption/' + quarterStart + '/' + quarterEnd, ids);
	}

	DownloadFile(fileName): any {
		return this.http.get(this.BaseURI_Account + "DownloadFile?fileName=" + fileName, {
			responseType: 'blob',
			observe: 'response'
		})
		.pipe(
			map((res: any) => {
				return new Blob([res.body], {
					type: 'application/pdf'
				});
			})
		);
	}

	DownloadCSVFile(fileName): any {
		return this.http.get(this.BaseURI_Account + "downloadCSVFile?fileName=" + fileName, {
			responseType: 'blob',
			observe: 'response'
		})
		.pipe(
			map((res: any) => {
				return new Blob([res.body], {
					type: 'application/vnd.ms-excel'
				});
			})
		);
	}

	CompetitiveSetList(userId) {
		return this.http.get(this.BaseURI_Account + 'CompetativeSetList?userId=' + userId);
	}

	GetMarkersDetailsByPropertyId(propertyId) {
		return this.http.get(this.BaseURI_Account + 'GetMarkersDetailsByPropertyId?PropertyId=' + propertyId);
	}

	MakePrimaryImage(imageId) {		
		return this.http.put(this.BaseURI_Account + 'MakePrimaryImage?ImageId=' + imageId, {});
	}

	DeleteImage(imageId) {		
		return this.http.delete(this.BaseURI_Account + 'DeleteImage?ImageId=' + imageId);
	}
}