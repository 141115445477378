import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpClientModule } from "@angular/common/http";
import { environment } from '../../environments/environment';


@Injectable({
	providedIn: 'root'
})
export class AdjustScoreWeightsService {

	//readonly BaseURI_YT = 'http://localhost:52014/';
	//PREVIOUS USED: readonly BaseURI_YT = 'http://dev.ytsys.net:22222/';

	BaseURI_YT = environment.BaseURI_APIExtension;

	constructor(private http: HttpClient) { }

	GetScoreWeights() {
		// returns a JSON object of all score weights, names are the same as column names so easy peasy

		console.log(`fetching score weights from server`);
		let res = this.http.get(this.BaseURI_YT + 'building-scores/score-weights');
		return res;

		// return this.TempGetScoreWeights();
	}

	GetDefaultScoreWeights() {
		// returns a JSON object of all score weights, names are the same as column names so easy peasy
		let scoreWeights = [
			{ScoreName:'ConstructionQuality', ScoreWeight: 15.00 },
			{ScoreName:'AskingRate', ScoreWeight: 5.00 },
			{ScoreName:'CommonAreaQuality', ScoreWeight: 12.50 },
			{ScoreName:'OnsiteAmenities', ScoreWeight: 12.50 },
			{ScoreName:'SizeStories', ScoreWeight: 10.00 },
			{ScoreName:'AgeBonus', ScoreWeight: 5.00 },
			{ScoreName:'LocationProminence', ScoreWeight: 10.00 },
			{ScoreName:'RelevanceToConsumer', ScoreWeight: 5.00 },
			{ScoreName:'LocationConvenience', ScoreWeight: 5.00 },
			{ScoreName:'LocationProximity', ScoreWeight: 10.00 },
			{ScoreName:'ParkingVisitorExperience', ScoreWeight: 5.00 },
			{ScoreName:'ParkingQuantity', ScoreWeight: 5.00 },
			{ScoreName:'ParkingQuality', ScoreWeight: 5.00 }
		];
		return scoreWeights;
	}

	SetScoreWeights(body) {
		// body is just a JSON object of all score weights, with names the same as column names
		return this.http.post(this.BaseURI_YT + 'building-scores/score-weights', body);
		console.log(`saving score weights to server`);
		// return this.TempSetScoreWeights(body);
	}

	async TempSetScoreWeights(body) {
		window.sessionStorage.scoreWeights = JSON.stringify(body);
	}
}
