/// <reference path="reports/reports.component.ts" />
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { HttpModule } from '@angular/http';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './account/login/login.component';
import { AppRoutingModule } from '../app/app-routing.module';
import { AccountService } from '../app/services/account.service';
import { MarketBuildingService } from '../app/services/market-building.service';
import { ImportExcelComponent } from './import/import-excel/import-excel.component';
import { OfficeMarketBuildingComponent } from './office-market-building/office-market-building.component';
import { BuildingScoresService } from '../app/services/building-scores.service';
import { AdjustScoreWeightsService } from '../app/services/adjust-score-weights.service';
import { LayoutComponent } from './layout/layout.component';
import { ForgotPasswordComponent } from './account/forgot-password/forgot-password.component';
import { NavMenuComponent } from '../app/nav-menu/nav-menu.component';
import { ChangePasswordComponent } from './account/change-password/change-password.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UsermanagementService } from './services/usermanagement.service';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { TableModule } from 'primeng/table'
import { ButtonModule } from 'primeng/button';
import { AlertModelPopupComponent } from './alert-model-popup/alert-model-popup.component';
import { UsermanagementComponent } from './account/usermanagement/usermanagement.component';
import { PortalPermissionModelpopupComponent } from '../app/portal-permission-modelpopup/portal-permission-modelpopup.component';
import { AgmCoreModule } from '@agm/core';
import { AuthguardGuard } from '../app/guards/authguard.guard';
import { MultiSelectModule } from 'primeng/multiselect';
import { SliderModule } from 'primeng/slider';
import { AccordionModule } from 'primeng/accordion';
import { AgmSnazzyInfoWindowModule } from '@agm/snazzy-info-window';
import { CalendarModule } from 'primeng/calendar';
import { CarouselModule } from 'primeng/carousel';
import { TabViewModule } from 'primeng/tabview';
import { MyDatePickerModule } from 'mydatepicker';
import * as moment from 'moment';
import { TenantLeasePortalComponent } from './tenant-lease-portal/tenant-lease-portal.component';
import { BuildingScoresComponent } from './building-scores/building-scores.component';
import { AdjustScoreWeightsComponent } from './adjust-score-weights/adjust-score-weights.component';
import { ChartModule } from 'primeng/chart';
import { RadioButtonModule } from 'primeng/radiobutton';
import { DropdownModule } from 'primeng/dropdown';
import { RenamecompetitivesetsComponent } from './account/renamecompetitivesets/renamecompetitivesets.component';
import { ReportsComponent } from '../app/reports/reports.component';
import { UploadReportsComponent } from '../app/upload-reports/upload-reports.component';
import { DatePipe } from '@angular/common';
import { ScoreWeightsComponent } from './account/score-weights/score-weights.component';
import { UserFiltersModalComponent } from './shared/user-filters-modal/user-filters-modal.component';
import { UserFilterAddModalComponent } from './shared/user-filter-add-modal/user-filter-add-modal.component';
import { FilterService } from './services/filter.service';
import { AssetManagementComponent } from './account/asset-management/asset-management.component';
import { ListRatingFiltersComponent } from './shared/list-rating-filters/list-rating-filters.component';
import { RatingFilterComponent } from './shared/rating-filter/rating-filter.component';

@NgModule({
	declarations: [
		AppComponent,
		AssetManagementComponent,
		LoginComponent,
		HomeComponent,
		ImportExcelComponent,
		OfficeMarketBuildingComponent,
		BuildingScoresComponent,
		AdjustScoreWeightsComponent,
		ScoreWeightsComponent,
		LayoutComponent,
		ForgotPasswordComponent,
		NavMenuComponent,
		ChangePasswordComponent,
		AlertModelPopupComponent,
		UsermanagementComponent,
		PortalPermissionModelpopupComponent,
		TenantLeasePortalComponent,
		RenamecompetitivesetsComponent,
		ReportsComponent,
		UploadReportsComponent,
		UserFiltersModalComponent,
		UserFilterAddModalComponent,
		ListRatingFiltersComponent,
		RatingFilterComponent
	],
	imports: [
		BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
		HttpClientModule,
		FormsModule,
		AppRoutingModule,
		HttpModule,
		AgmCoreModule,
		ReactiveFormsModule,
		AutoCompleteModule,
		TableModule,
		ButtonModule,
		AgmSnazzyInfoWindowModule,
		BrowserAnimationsModule,
		MultiSelectModule,
		CalendarModule,
		SliderModule,
		AccordionModule,
		MyDatePickerModule,
		ChartModule,
		RadioButtonModule,
		DropdownModule,
		CarouselModule,
		TabViewModule,
		RouterModule.forRoot([
		]),
		AgmCoreModule.forRoot({
			// please get your own API key here:
			// https://developers.google.com/maps/documentation/javascript/get-api-key?hl=en
			apiKey: 'AIzaSyCEZg5wala3IPlmNkj8vUCKIY09habOWCE'
		}),
	],
	providers: [AccountService, MarketBuildingService, UsermanagementService, AuthguardGuard, DatePipe, BuildingScoresService, AdjustScoreWeightsService, FilterService],
	bootstrap: [AppComponent],
	 schemas: [
		CUSTOM_ELEMENTS_SCHEMA
	],
})
export class AppModule { }
