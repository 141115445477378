import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ValidatorFn, FormControl  } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { AccountService } from '../services/account.service';
import { Router } from '@angular/router';
import { IMyDpOptions } from 'mydatepicker';
import { and } from '@angular/router/src/utils/collection';
import { environment } from '../../environments/environment';


  //const trimValidator: ValidatorFn = (control: FormControl) => {
  //  if (control.value.startsWith(' ')) {
  //    return {
  //      'trimError': { value: 'control has leading whitespace' }
  //    };
  //  }
  //  //if (control.value.endsWith(' ')) {
  //  //  return {
  //  //    'trimError': { value: 'control has trailing whitespace' }
  //  //  };
  //  //}

  //  return null;
  //};
@Component({
  selector: 'app-upload-reports',
  templateUrl: './upload-reports.component.html',
  styleUrls: ['./upload-reports.component.css']
})
export class UploadReportsComponent implements OnInit {

  readonly apiUrlPDFUploades = environment.BaseURI_API_Account + 'SaveUploadedPdfInFolder';

  @ViewChild('myInput')
  myInputVariable: ElementRef;
  uploadForm: FormGroup;
  alertMessage: string = null;
  loader: boolean = false;
  submitted = false;
  reportDetailList: any;
  pdfUrl: string = "";
  state: any;
  btnTextChange: string = "Submit";
  myDatePickerOptions: IMyDpOptions = {
    todayBtnTxt: 'Today',
    dateFormat: 'mm/dd/yyyy',
    ariaLabelInputField: '',
    firstDayOfWeek: 'mo',
    sunHighlight: true,
    inline: false,
  };
  selectedDate: Date;
  minLengtTextTitle: boolean = false;
  minLengtTextDescription: boolean = false;
  fileNotSelected: boolean = false;
  isFormResetCall: boolean = false;
  constructor(private formBuilder: FormBuilder, private httpClient: HttpClient, private accountServices: AccountService, private router: Router) {
    //const navigation = this.router.getCurrentNavigation();
    //if (navigation.extras)
    //this.state = navigation.extras as {
    //  reportID: number,
    //  title: string,
    //  description: string,
    //  createdDate: Date
    //};
    if (this.accountServices.reportDetails != undefined) {
      this.state = this.accountServices.reportDetails as {
      reportID: number,
      title: string,
      description: string,
      createdDate: Date
     };
    }
  }

  ngOnInit() {
    this.submitted = false;
    this.uploadForm = this.formBuilder.group({
      // pdfInput: ['', Validators.required],
      profile: ['', [Validators.required]],
      title: ['', [Validators.required,
      Validators.minLength(5)]],
      description: ['', [Validators.required,
      Validators.minLength(5)]],
      SelectedDate: ['', Validators.required],
    });
    //if (this.state.reportID != null) {
    //  reportID
    //}
    if (this.accountServices.reportDetails != undefined) {
      this.selectedDate = this.state.createdDate;
      this.uploadForm.setValue({ title: this.state.title, description: this.state.description, profile: 'nofile', SelectedDate: this.state.createdDate })
      this.accountServices.reportDetails = null;
    }
  }
  get f() { return this.uploadForm.controls; }

  onFileSelect(event) {

    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.uploadForm.get('profile').setValue(file);

    }
  }
  onSubmit() {
    this.loader = true;
    this.submitted = true;
    const formData = new FormData();
    if (this.uploadForm.value.title != undefined && this.uploadForm.value.title != "" && this.uploadForm.value.title != null) {
      this.uploadForm.value.title = this.uploadForm.value.title.trim();
    }
    if (this.uploadForm.value.description != undefined && this.uploadForm.value.description != "" && this.uploadForm.value.description != null) {
      this.uploadForm.value.description = this.uploadForm.value.description.trim();
    }
    if (this.uploadForm.value.title == "") {
      this.minLengtTextTitle = true;
    }
    if (this.uploadForm.value.description == "") {
      this.minLengtTextDescription = true;
    }
    if (this.state == undefined || this.state == null) {
      formData.append('reportId', "0");
    }
    else {
      formData.append('reportId', this.state.reportID);
    }
    if (this.state == undefined || this.state.reportID == "") {
      if (this.uploadForm.value.profile == "" || this.uploadForm.get('profile').value == "" || this.uploadForm.get('profile').value == null) {

        this.fileNotSelected = true;
        this.loader = false;
        alert("Select file.")
      }
    }
    if (this.uploadForm.invalid) {
      this.loader = false;
      //alert("Enter the details.");
      return;
    }
    
    else if (this.uploadForm.get('profile').value != "" || this.state.reportID != "") {
      if (this.uploadForm.get('profile').value == "nofile") {
        this.uploadForm.get('profile').value == "";
      }
      formData.append('file', this.uploadForm.get('profile').value);
      formData.append('title', this.uploadForm.get('title').value);
      formData.append('description', this.uploadForm.get('description').value);
      formData.append('selectedDated', this.uploadForm.get('SelectedDate').value);
     
      this.httpClient.post<any>(this.apiUrlPDFUploades, formData).subscribe(
        (res) => {
          if (this.state == undefined || this.state == null || this.state == "") {
            this.alertMessage = "File uploaded successfully."
            this.uploadForm.reset();
          }
          else {
            alert("Report detail updated successfully.");
            this.router.navigateByUrl('/reports');
          }
         
          this.loader = false;
          this.submitted = false;
        },
        (err) => {

          if (err.status == 400) {
            this.alertMessage = "File not uploaded."
            this.loader = false;
          }
          else {
            if (this.state == undefined || this.state == null || this.state == "") {
              this.alertMessage = "File uploaded successfully."
              this.uploadForm.reset();
            }
            else {
              alert("Report detail updated successfully.");
              this.router.navigateByUrl('/reports');
            }
            this.loader = false;
          }
          if (this.state == undefined || this.state == null || this.state == "") {
            this.uploadForm.reset();
          }
        }
      );
    }

    
  }

}
