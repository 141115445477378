import { Component, Input, OnInit, OnChanges, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { RatingFilter } from '../../core/models/common/rating-filter';
import { FilterMode } from '../../core/models/common/filter-mode';

const defaultMaxValue = 'No Max';
const defaultMinValue = 'No Min';

@Component({
    selector: 'app-rating-filter',
    templateUrl: './rating-filter.component.html',
    styleUrls: ['./rating-filter.component.css']
})
export class RatingFilterComponent implements OnInit {

    @Input() ratingFilter: RatingFilter;
    @Output() ratingFilterChange = new EventEmitter<any>();

    @Input() ratingLabel: string = 'Total Rating';

    @Input() filterMode: FilterMode;
    @Output() filterModeChange = new EventEmitter<FilterMode>();

    @Input() ratingMin: number = 0;
    @Input() ratingMax: number = 10;

    public FilterNumberRangeError: boolean = false;
	public FilterPercentRangeError: boolean = false;

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['filterMode']) {
            switch (this.filterMode) {
                case 'None':
                    this.ratingFilter.numeric = [defaultMaxValue, defaultMinValue];
                    this.ratingFilter.percentage = [defaultMaxValue, defaultMinValue];
                case 'Numeric':
                    this.ratingFilter.percentage = [defaultMaxValue, defaultMinValue];
                case 'Percent':
                    this.ratingFilter.numeric = [defaultMaxValue, defaultMinValue];
            }
        }
    }

    onFilterScoreChange(value: any, type: string) {
        if (type === 'min') {
            this.ratingFilter.rating[0] = value;
        } else if (type === 'max') {
            this.ratingFilter.rating[1] = value;
        }
        this.emitFilterChange();
    }

    onBuildingFilterChange(event: any, applyFilter: boolean = false) {
        this.emitFilterChange();
    }

    onFilterNumberChange() {
        this.ratingFilter.percentage = [defaultMaxValue, defaultMinValue];
        this.FilterNumberRangeError = this.checkMinMaxError(this.ratingFilter.numeric[0], this.ratingFilter.numeric[1]);

        this.emitFilterNumericPercentChange();
    }

    onFilterPercentChange() {
        this.ratingFilter.numeric = [defaultMaxValue, defaultMinValue];
        this.FilterPercentRangeError = this.checkMinMaxError(this.ratingFilter.percentage[0], this.ratingFilter.percentage[1]);

        this.emitFilterNumericPercentChange();
    }

    emitFilterNumericPercentChange() {
        if (!this.checkFilterNumberPercentEmpty(this.ratingFilter.numeric) && this.checkFilterNumberPercentEmpty(this.ratingFilter.percentage)) {
            this.filterModeChange.emit('Numeric');
        } else if (!this.checkFilterNumberPercentEmpty(this.ratingFilter.percentage) && this.checkFilterNumberPercentEmpty(this.ratingFilter.numeric)) {
            this.filterModeChange.emit('Percent');
        } else {
            this.filterModeChange.emit('None');
        }
    }

    private checkFilterNumberPercentEmpty(value: string[]): boolean {
        return ((value[0] == defaultMinValue || value[0] == '' || isNaN(Number(value[0]))) && (value[1] == defaultMaxValue || value[1] == '' || isNaN(Number(value[1]))));
    }

    emitFilterChange() {
        this.ratingFilterChange.emit(this.ratingFilter);
    }

    private checkMinMaxError(min: any, max: any): boolean {
        let error = (max !== defaultMaxValue && max !== '' && isNaN(Number(max))) || (min !== defaultMinValue && min !== '' && isNaN(Number(min)));

		if(!error && !isNaN(Number(max)) && !isNaN(Number(min))){
			return error = max > min;
		}

        return false;
    }
}
