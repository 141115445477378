import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgForm, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from '../../../environments/environment';

@Component({
	selector: 'app-asset-management',
	templateUrl: './asset-management.component.html',
	styleUrls: ['./asset-management.component.css']
})

export class AssetManagementComponent implements OnInit {

	apiUrlBase = environment.BaseURI_API + 'Asset/';

	uploadForm: FormGroup;
	uploadSuccessAlert: boolean = false;
	uploadErrorMessage: string = null;
	loader: boolean = false;
	edited: boolean = false;
	@ViewChild('myInput')
	myInputVariable: ElementRef;


	constructor(private formBuilder: FormBuilder, private httpClient: HttpClient) { }

	ngOnInit() {
		this.uploadForm = this.formBuilder.group({
			profile: ['']
		});

	}

	onFileSelect(event) {

		if (event.target.files.length > 0) {
			const file = event.target.files[0];
			this.uploadForm.get('profile').setValue(file);
		}
	}

	onSubmitTeamPhoto() {
		this.uploadSuccessAlert = false;
		this.uploadErrorMessage = null;

		const formData = new FormData();
		
		formData.append('file', this.uploadForm.get('profile').value);
		this.edited = true;
		if (this.uploadForm.get('profile').value != "" && this.uploadForm.get('profile').value != undefined && this.uploadForm.get('profile').value != null) {
			this.loader = true;

			this.httpClient.post<any>(this.apiUrlBase + 'team-photo', formData).subscribe(
				(res) => {
					this.uploadSuccessAlert = true;
					this.myInputVariable.nativeElement.value = "";
					this.loader = false;
				},
				(err) => {
					console.log(err);
					if (err.status == 400) {
						this.uploadErrorMessage = "File not uploaded. " + err.error.message;
						this.myInputVariable.nativeElement.value = "";
						this.loader = false;
					}
					else {
						this.uploadSuccessAlert = true;
						this.myInputVariable.nativeElement.value = "";
						this.loader = false;
					}

				}
			);
		}
		else {
			alert("Please select team photo file.");
		}
	}

	onSubmitBuildingRatingMethodology() {
		this.uploadSuccessAlert = false;
		this.uploadErrorMessage = null;

		const formData = new FormData();
		
		formData.append('file', this.uploadForm.get('profile').value);
		this.edited = true;
		if (this.uploadForm.get('profile').value != "" && this.uploadForm.get('profile').value != undefined && this.uploadForm.get('profile').value != null) {
			this.loader = true;

			this.httpClient.post<any>(this.apiUrlBase + 'building-rating-methodology', formData).subscribe(
				(res) => {
					this.uploadSuccessAlert = true;
					this.myInputVariable.nativeElement.value = "";
					this.loader = false;
				},
				(err) => {
					console.log(err);
					if (err.status == 400) {
						this.uploadErrorMessage = "File not uploaded. " + err.error.message;
						this.myInputVariable.nativeElement.value = "";
						this.loader = false;
					}
					else {
						this.uploadSuccessAlert = true;
						this.myInputVariable.nativeElement.value = "";
						this.loader = false;
					}

				}
			);
		}
		else {
			alert("Please select building rating methodology pdf.");
		}
	}
}
