import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpClientModule } from "@angular/common/http";
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { LeaseFilter } from '../core/models/lease-filter';

@Injectable({
  providedIn: 'root'
})
export class TenantLeaseService {

  BaseURI = environment.BaseURI_API_TenantLease;

  constructor(private http: HttpClient) { }
  rangeNumberOfFloorMaxValues: any;
  rangePropertySizeMin: any;
  rangePropertySizeMax: any;
  inputparkingRatioMinValue: string;
  inputparkingRatioMaxValue: string;
  rangeWalkScoreValuesMin: any;
  rangeWalkScoreValuesMax: any;
  inputMinValueAskingRent: string;
  inputMaxValueAskingRent: string;
  inputLightRailMinValue: string;
  inputLightRailMaxValue: string;
  inputRatingToRentRatioCurrentMinValue: string;
	inputRatingToRentRatioCurrentMaxValue: string;
  officeMarketBuildingList: any = [];
  tenantLeaseBuildingList: any = [];
  containerMapId: any;
  propertyName: string;
  companyName: string;
  deletedLeaseIdFromList: string = "";
  leaseIdSingleSelection: string = "";

  TenantLeaseBuildingClassList() {
    return this.http.get<string[]>(this.BaseURI + 'GetTenantLeaseBuildingClassList');
  }
  TenantLeaseSubMarketList() {
    return this.http.get<string[]>(this.BaseURI + 'TenantLeaseSubMarketList');
  }
  TenantLeaseDistrictList() {
    return this.http.get<string[]>(this.BaseURI + 'TenantLeaseDistrictList');
  }
  TenantLeaseLeaseTypeList() {
    return this.http.get<string[]>(this.BaseURI + 'TenantLeaseLeaseTypeList');
  }
  TenantLeaseDealTypeList() {
    return this.http.get<string[]>(this.BaseURI + 'TenantLeaseDealTypeList');
  }
  TenantLeaseSpaceConditionList() {
    return this.http.get<string[]>(this.BaseURI + 'TenantLeaseSpaceConditionList');
  }
  TenantLeaseLinkedInGroupList() {
    return this.http.get<string[]>(this.BaseURI + 'TenantLeaseLinkedInGroupList');
  }
  TenantLeaseLinkedInClassificationList() {
    return this.http.get<string[]>(this.BaseURI + 'TenantLeaseLinkedInClassificationList');
  }
  TenantCompanyIndustryList() {
    return this.http.get<string[]>(this.BaseURI + 'TenantCompanyIndustryList');
  }
  TenantLeaseSquareFootageMinMaxValue() {
    return this.http.get(this.BaseURI + 'TenantLeaseSquareFootageMinMaxValue');
  }
  TenantLeaseDetailbyFilter(filter: LeaseFilter) {
    return this.http.post(this.BaseURI + 'TenantLeaseDetailbyFilter', filter);
  }

  TenantLeaseDetailbyId(propertyId) {
    var body = {
      PropertyId:propertyId
    };

    return this.http.post(this.BaseURI + 'TenantLeaseDetailbyFilter', body);
  }

  TenantLeaseDetailByPropertyIds(ids, scoreWeightId) {
    return this.http.post(this.BaseURI + 'TenantLeaseDetailByPropertyIds/' + scoreWeightId, ids);
  }

  DownloadTenantPDFThreeRowInSinglePage(fileName): any {
    return this.http.get(this.BaseURI + "DownloadTenantPDFThreeRowInSinglePage?fileName=" + fileName, {
      responseType: 'blob',
      observe: 'response'
    })
      .pipe(
        map((res: any) => {
          return new Blob([res.body], { type: 'application/pdf' });
        })
      );
  }

  DownloadTenantPDFSingleRowInSinglePage(fileName): any {
    return this.http.get(this.BaseURI + "DownloadTenantPDFSingleRowInSinglePage?fileName=" + fileName, {
      responseType: 'blob',
      observe: 'response'
    })
      .pipe(
        map((res: any) => {
          return new Blob([res.body], { type: 'application/pdf' });
        })
      );
  }

  PropertyLeaseCompPDF(multipleName, propertyName, selectYearBuildDate, leaseIdList) {
    var body = {
      FileName: multipleName,
      PropertyName: propertyName,
      SelectYearBuildDate: selectYearBuildDate,
      LeaseIdList: leaseIdList
    };
    return this.http.post(this.BaseURI + 'LeaseCompPDF', body, { responseType: 'blob' });
  }
  CompanyLeaseCompPDF(multipleName, companyName, selectYearBuildDate, leaseIdList) {
    var body = {
      FileName: multipleName,
      CompanyName: companyName,
      SelectYearBuildDate: selectYearBuildDate,
      LeaseIdList: leaseIdList
    };
    return this.http.post(this.BaseURI + 'LeaseCompPDF', body, { responseType: 'blob' });
  }

  LeaseCompPdfExport() {
    //Filter out duplicate Lease Comps with ID of -1
    var filterList = this.tenantLeaseBuildingList.filter(obj => obj.LeaseId !== -1);
		return this.http.post(this.BaseURI + 'LeaseCompPdfExport', filterList, { responseType: 'blob' });
	}

  LeaseThreeCompPdfExport() {
    const ids: number[] = this.tenantLeaseBuildingList.map(obj => obj.leaseId);
		return this.http.post(this.BaseURI + 'LeaseThreeCompPdfExport', ids, { responseType: 'blob' });
	}

  LeaseExcelExport(scoreWeightId: string) {
		const ids: number[] = this.tenantLeaseBuildingList.map(obj => obj.leaseId);
		return this.http.post(this.BaseURI + 'LeaseExcelExport/' + scoreWeightId, ids, { responseType: 'blob' });
	}

  GetPropertyDetails(multipleName, propertyName, selectYearBuildDate, leaseIdList) {
    var body = {
      FileName: multipleName,
      PropertyName: propertyName,
      SelectYearBuildDate: selectYearBuildDate,
      LeaseIdList: leaseIdList
    };
    return this.http.post(this.BaseURI + 'TenantLeaseDetailbyFilter', body);
  }
  GetCompanyNameDetails(multipleName, companyName, selectYearBuildDate, leaseIdList) {
    var body = {
      FileName: multipleName,
      CompanyName: companyName,
      SelectYearBuildDate: selectYearBuildDate,
      LeaseIdList: leaseIdList
    };
    return this.http.post(this.BaseURI + 'TenantLeaseDetailbyFilter', body);
  }

  GenerateSinglePopupRecordPdf(leaseId): any {
    return this.http.post(this.BaseURI + 'GenerateSinglePopupRecordPdf?LeaseId=' + leaseId, null, { responseType: 'blob' });
  }
  GetTenantLeaseStatus(): any {
    return this.http.get<string[]>(this.BaseURI + "GetTenantLeaseStatus")
  }
  GetTenantCity(): any {
    return this.http.get<string[]>(this.BaseURI + "GetTenantCity")
  }
  GetTenantZip(): any {
    return this.http.get<string[]>(this.BaseURI + "GetTenantZip")
  }
}


  
