import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FilterMode } from '../../core/models/common/filter-mode';
import { BuildingRatingsFilter } from '../../core/models/building-ratings-filter';
import { BuildingScoresService } from '../../services/building-scores.service';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';

const defaultMaxValue = 'No Max';
const defaultMinValue = 'No Min';

@Component({
    selector: 'app-list-rating-filters',
    templateUrl: './list-rating-filters.component.html',
    styleUrls: ['./list-rating-filters.component.css']
})
export class ListRatingFiltersComponent implements OnInit {

    @Input() ratingFilters: BuildingRatingsFilter;

    public filterMode: FilterMode = 'None';

    private allProperties: any[] = null;
    private allPropertiesScoreWeightId: string;

    public filterScopes: any[];
    public selectedFilterScope: string;

    constructor(private scoreService: BuildingScoresService) {
	}

    ngOnInit() {
        this.filterScopes = [
			{ label: 'Overall', value: 'overall' },
			{ label: 'Within Each Submarket', value: 'submarket' },
			{ label: 'Within Each District', value: 'district' }
		];

		this.selectedFilterScope = this.filterScopes[0];
    }

    public clearRatingFilters() {
        this.filterMode = 'None';
        
		this.ratingFilters.total.rating = [0,105];
		this.ratingFilters.total.numeric = [defaultMinValue,defaultMaxValue];
		this.ratingFilters.total.percentage = [defaultMinValue,defaultMaxValue];
		
		this.ratingFilters.constructionQuality.rating = [0,10];
		this.ratingFilters.constructionQuality.numeric = [defaultMinValue,defaultMaxValue];
		this.ratingFilters.constructionQuality.percentage = [defaultMinValue,defaultMaxValue];
		
		this.ratingFilters.askingRate.rating = [0,10];
		this.ratingFilters.askingRate.numeric = [defaultMinValue,defaultMaxValue];
		this.ratingFilters.askingRate.percentage = [defaultMinValue,defaultMaxValue];
		
		this.ratingFilters.commonAreaQuality.rating = [0,10];
		this.ratingFilters.commonAreaQuality.numeric = [defaultMinValue,defaultMaxValue];
		this.ratingFilters.commonAreaQuality.percentage = [defaultMinValue,defaultMaxValue];
		
		this.ratingFilters.onSiteAmenities.rating = [0,10];
		this.ratingFilters.onSiteAmenities.numeric = [defaultMinValue,defaultMaxValue];
		this.ratingFilters.onSiteAmenities.percentage = [defaultMinValue,defaultMaxValue];
		
		this.ratingFilters.sizeStories.rating = [0,10];
		this.ratingFilters.sizeStories.numeric = [defaultMinValue,defaultMaxValue];
		this.ratingFilters.sizeStories.percentage = [defaultMinValue,defaultMaxValue];
		
		this.ratingFilters.ageBonus.rating = [0,10];
		this.ratingFilters.ageBonus.numeric = [defaultMinValue,defaultMaxValue];
		this.ratingFilters.ageBonus.percentage = [defaultMinValue,defaultMaxValue];
		
		this.ratingFilters.locationProminence.rating = [0,10];
		this.ratingFilters.locationProminence.numeric = [defaultMinValue,defaultMaxValue];
		this.ratingFilters.locationProminence.percentage = [defaultMinValue,defaultMaxValue];
		
		this.ratingFilters.relevanceToConsumer.rating = [0,10];
		this.ratingFilters.relevanceToConsumer.numeric = [defaultMinValue,defaultMaxValue];
		this.ratingFilters.relevanceToConsumer.percentage = [defaultMinValue,defaultMaxValue];
		
		this.ratingFilters.locationConvenience.rating = [0,10];
		this.ratingFilters.locationConvenience.numeric = [defaultMinValue,defaultMaxValue];
		this.ratingFilters.locationConvenience.percentage = [defaultMinValue,defaultMaxValue];
		
		this.ratingFilters.locationProximity.rating = [0,10];
		this.ratingFilters.locationProximity.numeric = [defaultMinValue,defaultMaxValue];
		this.ratingFilters.locationProximity.percentage = [defaultMinValue,defaultMaxValue];
		
		this.ratingFilters.parkingVisitorExperience.rating = [0,10];
		this.ratingFilters.parkingVisitorExperience.numeric = [defaultMinValue,defaultMaxValue];
		this.ratingFilters.parkingVisitorExperience.percentage = [defaultMinValue,defaultMaxValue];
		
		this.ratingFilters.parkingQuantity.rating = [0,10];
		this.ratingFilters.parkingQuantity.numeric = [defaultMinValue,defaultMaxValue];
		this.ratingFilters.parkingQuantity.percentage = [defaultMinValue,defaultMaxValue];
		
		this.ratingFilters.parkingQuality.rating = [0,10];
		this.ratingFilters.parkingQuality.numeric = [defaultMinValue,defaultMaxValue];
		this.ratingFilters.parkingQuality.percentage = [defaultMinValue,defaultMaxValue];
    }

    private getAllPropertyScores(): Observable<any[]> {
        if (this.ratingFilters.scoreWeightId !== this.allPropertiesScoreWeightId) {

            return this.scoreService.GetAllPropertyScores(this.ratingFilters.scoreWeightId).pipe(
                tap((res: any) => {
                    this.allProperties = res;
                    this.allPropertiesScoreWeightId = this.ratingFilters.scoreWeightId;

                    this.allProperties = this.sortBuildingsByDistrict(
                        this.sortBuildingsBySubmarket(
                            this.sortBuildingsByScores(this.allProperties, "OverallRank")
                        )
                    );
                }),
                catchError(err => {
                    console.log('Error retrieving property scores', err);
                    return of([]);
                })
            );
        }

        return of(this.allProperties || []);
    }

    public filterBuildings(buildings: any): Observable<any> {
        return this.getAllPropertyScores().pipe(
            map((scores: any[]) => {

                buildings.forEach(building => {
                    const score = scores.find(s => s.propertyID == building.propertyId);
                    building.score = score ? score : null;
                });     

                buildings = buildings.filter(obj => obj.score != null);

                return this.processBuildings(buildings);
            }),
            catchError((err) => {
                console.log('Error retrieving scores', err);
                return of(buildings);
            })
        );
    }

    private processBuildings(buildings: any) {
        switch (this.selectedFilterScope) {
            case 'overall':
                return this.buildFilters(buildings);
            case 'submarket':
                return this.processBuildingsBySubMarket(buildings);
            case 'district':
                return this.processBuildingsByDistrict(buildings);
            default:
                return this.buildFilters(buildings);
        }
    }

    private processBuildingsBySubMarket(buildings: any[]): any[] {
        const groupedBySubMarket = buildings.reduce((acc: any, building: any) => {
            if (building.submarket !== null) {
                if (!acc[building.submarket]) {
                    acc[building.submarket] = [];
                }
                acc[building.submarket].push(building);
            }
            return acc;
        }, {});

        const results: any[] = [];
        for (const group of Object.values(groupedBySubMarket)) {
            const processedGroup = this.buildFilters(group);
            results.push.apply(results, processedGroup);
        }
        return results;
    }

    private processBuildingsByDistrict(buildings: any[]): any[] {
        const groupedByDistrict = buildings.reduce((acc: any, building: any) => {
            if (building.district !== null) {
                if (!acc[building.district]) {
                    acc[building.district] = [];
                }
                acc[building.district].push(building);
            }
            return acc;
        }, {});

        const results: any[] = [];
        for (const group of Object.values(groupedByDistrict)) {
          const processedGroup = this.buildFilters(group);
          results.push.apply(results, processedGroup);
        }
    
        return results;
    }

    private buildFilters(buildings: any) {
        switch (this.filterMode) {
            case 'None':
                return this.filterBuildingScores(buildings);
            case 'Numeric':
                buildings = this.filterBuildingsByNumeric(buildings);
                return this.filterBuildingScores(buildings);
            case 'Percent':
                buildings = this.filterBuildingsByPercentage(buildings);
                return this.filterBuildingScores(buildings);
        }
    }

    private filterBuildingScores(buildings: any): any {        
        //console.log("filterBuildingScores", buildings);
        return buildings.filter((building) => {
            return (
                building.score.totalScore !== null &&
                building.score.totalScore >= this.ratingFilters.total.rating[0] &&
                building.score.totalScore <= this.ratingFilters.total.rating[1] &&
                building.score.constructionQuality !== null &&
                building.score.constructionQuality >= this.ratingFilters.constructionQuality.rating[0] &&
                building.score.constructionQuality <= this.ratingFilters.constructionQuality.rating[1] &&
                building.score.askingRate !== null &&
                building.score.askingRate >= this.ratingFilters.askingRate.rating[0] &&
                building.score.askingRate <= this.ratingFilters.askingRate.rating[1] &&
                building.score.commonAreaQuality !== null &&
                building.score.commonAreaQuality >= this.ratingFilters.commonAreaQuality.rating[0] &&
                building.score.commonAreaQuality <= this.ratingFilters.commonAreaQuality.rating[1] &&
                building.score.onsiteAmenities !== null &&
                building.score.onsiteAmenities >= this.ratingFilters.onSiteAmenities.rating[0] &&
                building.score.onsiteAmenities <= this.ratingFilters.onSiteAmenities.rating[1] &&
                building.score.sizeStories !== null &&
                building.score.sizeStories >= this.ratingFilters.sizeStories.rating[0] &&
                building.score.sizeStories <= this.ratingFilters.sizeStories.rating[1] &&
                building.score.ageBonus !== null &&
                building.score.ageBonus >= this.ratingFilters.ageBonus.rating[0] &&
                building.score.ageBonus <= this.ratingFilters.ageBonus.rating[1] &&
                building.score.locationProminence !== null &&
                building.score.locationProminence >= this.ratingFilters.locationProminence.rating[0] &&
                building.score.locationProminence <= this.ratingFilters.locationProminence.rating[1] &&
                building.score.relevanceToConsumer !== null &&
                building.score.relevanceToConsumer >= this.ratingFilters.relevanceToConsumer.rating[0] &&
                building.score.relevanceToConsumer <= this.ratingFilters.relevanceToConsumer.rating[1] &&
                building.score.locationConvenience !== null &&
                building.score.locationConvenience >= this.ratingFilters.locationConvenience.rating[0] &&
                building.score.locationConvenience <= this.ratingFilters.locationConvenience.rating[1] &&
                building.score.locationProximity !== null &&
                building.score.locationProximity >= this.ratingFilters.locationProximity.rating[0] &&
                building.score.locationProximity <= this.ratingFilters.locationProximity.rating[1] &&
                building.score.parkingVisitorExperience !== null &&
                building.score.parkingVisitorExperience >= this.ratingFilters.parkingVisitorExperience.rating[0] &&
                building.score.parkingVisitorExperience <= this.ratingFilters.parkingVisitorExperience.rating[1] &&
                building.score.parkingQuantity !== null &&
                building.score.parkingQuantity >= this.ratingFilters.parkingQuantity.rating[0] &&
                building.score.parkingQuantity <= this.ratingFilters.parkingQuantity.rating[1] &&
                building.score.parkingQuality !== null &&
                building.score.parkingQuality >= this.ratingFilters.parkingQuality.rating[0] &&
                building.score.parkingQuality <= this.ratingFilters.parkingQuality.rating[1]              
            );
        });
    }

    private filterBuildingsByPercentage(buildings: any): any {
        // Calculate the percentile rank of a building based on a specific score property
        const getPercentile = (value: number, sortedValues: number[]): number => {
            const rank = sortedValues.indexOf(value) + 1;
            return (rank / sortedValues.length) * 100;
        };
    
        // Get valid percentage range
        const getValidPercentageRange = (percentage: any[]): number[] => {
            const min = typeof percentage[0] === 'number' && percentage[0] >= 0 && percentage[0] <= 100 ? percentage[0] : 0;
            const max = typeof percentage[1] === 'number' && percentage[1] >= 0 && percentage[1] <= 100 ? percentage[1] : 100;
            return [min, max];
        };
    
        // Sort the score properties for each filter
        const sortedScores = {
            totalScore: this.allProperties.map(b => b.totalScore).filter(v => v !== null).sort((a, b) => b - a),
            constructionQuality: this.allProperties.map(b => b.constructionQuality).filter(v => v !== null).sort((a, b) => b - a),
            askingRate: this.allProperties.map(b => b.askingRate).filter(v => v !== null).sort((a, b) => b - a),
            commonAreaQuality: this.allProperties.map(b => b.commonAreaQuality).filter(v => v !== null).sort((a, b) => b - a),
            onSiteAmenities: this.allProperties.map(b => b.onsiteAmenities).filter(v => v !== null).sort((a, b) => b - a),
            sizeStories: this.allProperties.map(b => b.sizeStories).filter(v => v !== null).sort((a, b) => b - a),
            ageBonus: this.allProperties.map(b => b.ageBonus).filter(v => v !== null).sort((a, b) => b - a),
            locationProminence: this.allProperties.map(b => b.locationProminence).filter(v => v !== null).sort((a, b) => b - a),
            relevanceToConsumer: this.allProperties.map(b => b.relevanceToConsumer).filter(v => v !== null).sort((a, b) => b - a),
            locationConvenience: this.allProperties.map(b => b.locationConvenience).filter(v => v !== null).sort((a, b) => b - a),
            locationProximity: this.allProperties.map(b => b.locationProximity).filter(v => v !== null).sort((a, b) => b - a),
            parkingVisitorExperience: this.allProperties.map(b => b.parkingVisitorExperience).filter(v => v !== null).sort((a, b) => b - a),
            parkingQuantity: this.allProperties.map(b => b.parkingQuantity).filter(v => v !== null).sort((a, b) => b - a),
            parkingQuality: this.allProperties.map(b => b.parkingQuality).filter(v => v !== null).sort((a, b) => b - a)
        };
    
        return buildings.filter(building => {
            return (
                building.score.totalScore !== null &&
                getPercentile(building.score.totalScore, sortedScores.totalScore) >= getValidPercentageRange(this.ratingFilters.total.percentage)[0] &&
                getPercentile(building.score.totalScore, sortedScores.totalScore) <= getValidPercentageRange(this.ratingFilters.total.percentage)[1] &&
    
                building.score.constructionQuality !== null &&
                getPercentile(building.score.constructionQuality, sortedScores.constructionQuality) >= getValidPercentageRange(this.ratingFilters.constructionQuality.percentage)[0] &&
                getPercentile(building.score.constructionQuality, sortedScores.constructionQuality) <= getValidPercentageRange(this.ratingFilters.constructionQuality.percentage)[1] &&
    
                building.score.askingRate !== null &&
                getPercentile(building.score.askingRate, sortedScores.askingRate) >= getValidPercentageRange(this.ratingFilters.askingRate.percentage)[0] &&
                getPercentile(building.score.askingRate, sortedScores.askingRate) <= getValidPercentageRange(this.ratingFilters.askingRate.percentage)[1] &&
    
                building.score.commonAreaQuality !== null &&
                getPercentile(building.score.commonAreaQuality, sortedScores.commonAreaQuality) >= getValidPercentageRange(this.ratingFilters.commonAreaQuality.percentage)[0] &&
                getPercentile(building.score.commonAreaQuality, sortedScores.commonAreaQuality) <= getValidPercentageRange(this.ratingFilters.commonAreaQuality.percentage)[1] &&
    
                building.score.onsiteAmenities !== null &&
                getPercentile(building.score.onsiteAmenities, sortedScores.onSiteAmenities) >= getValidPercentageRange(this.ratingFilters.onSiteAmenities.percentage)[0] &&
                getPercentile(building.score.onsiteAmenities, sortedScores.onSiteAmenities) <= getValidPercentageRange(this.ratingFilters.onSiteAmenities.percentage)[1] &&
    
                building.score.sizeStories !== null &&
                getPercentile(building.score.sizeStories, sortedScores.sizeStories) >= getValidPercentageRange(this.ratingFilters.sizeStories.percentage)[0] &&
                getPercentile(building.score.sizeStories, sortedScores.sizeStories) <= getValidPercentageRange(this.ratingFilters.sizeStories.percentage)[1] &&
    
                building.score.ageBonus !== null &&
                getPercentile(building.score.ageBonus, sortedScores.ageBonus) >= getValidPercentageRange(this.ratingFilters.ageBonus.percentage)[0] &&
                getPercentile(building.score.ageBonus, sortedScores.ageBonus) <= getValidPercentageRange(this.ratingFilters.ageBonus.percentage)[1] &&
    
                building.score.locationProminence !== null &&
                getPercentile(building.score.locationProminence, sortedScores.locationProminence) >= getValidPercentageRange(this.ratingFilters.locationProminence.percentage)[0] &&
                getPercentile(building.score.locationProminence, sortedScores.locationProminence) <= getValidPercentageRange(this.ratingFilters.locationProminence.percentage)[1] &&
    
                building.score.relevanceToConsumer !== null &&
                getPercentile(building.score.relevanceToConsumer, sortedScores.relevanceToConsumer) >= getValidPercentageRange(this.ratingFilters.relevanceToConsumer.percentage)[0] &&
                getPercentile(building.score.relevanceToConsumer, sortedScores.relevanceToConsumer) <= getValidPercentageRange(this.ratingFilters.relevanceToConsumer.percentage)[1] &&
    
                building.score.locationConvenience !== null &&
                getPercentile(building.score.locationConvenience, sortedScores.locationConvenience) >= getValidPercentageRange(this.ratingFilters.locationConvenience.percentage)[0] &&
                getPercentile(building.score.locationConvenience, sortedScores.locationConvenience) <= getValidPercentageRange(this.ratingFilters.locationConvenience.percentage)[1] &&
    
                building.score.locationProximity !== null &&
                getPercentile(building.score.locationProximity, sortedScores.locationProximity) >= getValidPercentageRange(this.ratingFilters.locationProximity.percentage)[0] &&
                getPercentile(building.score.locationProximity, sortedScores.locationProximity) <= getValidPercentageRange(this.ratingFilters.locationProximity.percentage)[1] &&
    
                building.score.parkingVisitorExperience !== null &&
                getPercentile(building.score.parkingVisitorExperience, sortedScores.parkingVisitorExperience) >= getValidPercentageRange(this.ratingFilters.parkingVisitorExperience.percentage)[0] &&
                getPercentile(building.score.parkingVisitorExperience, sortedScores.parkingVisitorExperience) <= getValidPercentageRange(this.ratingFilters.parkingVisitorExperience.percentage)[1] &&
    
                building.score.parkingQuantity !== null &&
                getPercentile(building.score.parkingQuantity, sortedScores.parkingQuantity) >= getValidPercentageRange(this.ratingFilters.parkingQuantity.percentage)[0] &&
                getPercentile(building.score.parkingQuantity, sortedScores.parkingQuantity) <= getValidPercentageRange(this.ratingFilters.parkingQuantity.percentage)[1] &&
    
                building.score.parkingQuality !== null &&
                getPercentile(building.score.parkingQuality, sortedScores.parkingQuality) >= getValidPercentageRange(this.ratingFilters.parkingQuality.percentage)[0] &&
                getPercentile(building.score.parkingQuality, sortedScores.parkingQuality) <= getValidPercentageRange(this.ratingFilters.parkingQuality.percentage)[1]
            );
        });
    }

    private filterBuildingsByNumeric(buildings: any): any {

        var propertyRank;
        switch (this.selectedFilterScope) {
            case 'overall':
                propertyRank = "OverallRank";
                break;
            case 'submarket':
                propertyRank = "SubmarketRank";
                break;
            case 'district':
                propertyRank = "DistrictRank";
                break;
            default:
                propertyRank = "OverallRank";
                break;
        }

        const getValidNumericRange = (numeric: any[]): number[] => {
            const min = typeof numeric[0] === 'number' && numeric[0] >= 1 && numeric[0] <= 1000 ? numeric[0] : 0;
            const max = typeof numeric[1] === 'number' && numeric[1] >= 1 && numeric[1] <= 1000 ? numeric[1] : 10000;
            return [min, max];
        };

        const getRankedBuildings = (scoreProperty: string, minRank: number, maxRank: number): any[] => {
            const scorePropertyRankType = scoreProperty + propertyRank;

            return buildings.filter(b => b.score[scorePropertyRankType] >= minRank && b.score[scorePropertyRankType] <= maxRank);
        };
    
        return buildings.filter(building => {
            return (
                building.score.totalScore !== null &&
                getRankedBuildings('totalScore', getValidNumericRange(this.ratingFilters.total.numeric)[0], getValidNumericRange(this.ratingFilters.total.numeric)[1]).includes(building) &&
    
                building.score.constructionQuality !== null &&
                getRankedBuildings('constructionQuality', getValidNumericRange(this.ratingFilters.constructionQuality.numeric)[0], getValidNumericRange(this.ratingFilters.constructionQuality.numeric)[1]).includes(building) &&
    
                building.score.askingRate !== null &&
                getRankedBuildings('askingRate', getValidNumericRange(this.ratingFilters.askingRate.numeric)[0], getValidNumericRange(this.ratingFilters.askingRate.numeric)[1]).includes(building) &&
    
                building.score.commonAreaQuality !== null &&
                getRankedBuildings('commonAreaQuality', getValidNumericRange(this.ratingFilters.commonAreaQuality.numeric)[0], getValidNumericRange(this.ratingFilters.commonAreaQuality.numeric)[1]).includes(building) &&
    
                building.score.onsiteAmenities !== null &&
                getRankedBuildings('onsiteAmenities', getValidNumericRange(this.ratingFilters.onSiteAmenities.numeric)[0], getValidNumericRange(this.ratingFilters.onSiteAmenities.numeric)[1]).includes(building) &&
    
                building.score.sizeStories !== null &&
                getRankedBuildings('sizeStories', getValidNumericRange(this.ratingFilters.sizeStories.numeric)[0], getValidNumericRange(this.ratingFilters.sizeStories.numeric)[1]).includes(building) &&
    
                building.score.ageBonus !== null &&
                getRankedBuildings('ageBonus', getValidNumericRange(this.ratingFilters.ageBonus.numeric)[0], getValidNumericRange(this.ratingFilters.ageBonus.numeric)[1]).includes(building) &&
    
                building.score.locationProminence !== null &&
                getRankedBuildings('locationProminence', getValidNumericRange(this.ratingFilters.locationProminence.numeric)[0], getValidNumericRange(this.ratingFilters.locationProminence.numeric)[1]).includes(building) &&
    
                building.score.relevanceToConsumer !== null &&
                getRankedBuildings('relevanceToConsumer', getValidNumericRange(this.ratingFilters.relevanceToConsumer.numeric)[0], getValidNumericRange(this.ratingFilters.relevanceToConsumer.numeric)[1]).includes(building) &&
    
                building.score.locationConvenience !== null &&
                getRankedBuildings('locationConvenience', getValidNumericRange(this.ratingFilters.locationConvenience.numeric)[0], getValidNumericRange(this.ratingFilters.locationConvenience.numeric)[1]).includes(building) &&
    
                building.score.locationProximity !== null &&
                getRankedBuildings('locationProximity', getValidNumericRange(this.ratingFilters.locationProximity.numeric)[0], getValidNumericRange(this.ratingFilters.locationProximity.numeric)[1]).includes(building) &&
    
                building.score.parkingVisitorExperience !== null &&
                getRankedBuildings('parkingVisitorExperience', getValidNumericRange(this.ratingFilters.parkingVisitorExperience.numeric)[0], getValidNumericRange(this.ratingFilters.parkingVisitorExperience.numeric)[1]).includes(building) &&
    
                building.score.parkingQuantity !== null &&
                getRankedBuildings('parkingQuantity', getValidNumericRange(this.ratingFilters.parkingQuantity.numeric)[0], getValidNumericRange(this.ratingFilters.parkingQuantity.numeric)[1]).includes(building) &&
    
                building.score.parkingQuality !== null &&
                getRankedBuildings('parkingQuality', getValidNumericRange(this.ratingFilters.parkingQuality.numeric)[0], getValidNumericRange(this.ratingFilters.parkingQuality.numeric)[1]).includes(building)
            );
        });
    }

	private sortBuildingsBySubmarket(buildings: any) {
		const results: any[] = [];

		const groupedBySubMarket = buildings.reduce((acc: any, building: any) => {
			const submarketKey = building.submarket || "null";
			if (!acc[submarketKey]) {
				acc[submarketKey] = [];
			}
			acc[submarketKey].push(building);
			return acc;
		}, {});

        for (const key in groupedBySubMarket) {
            var group = groupedBySubMarket[key];

            for (let i = 0; i < group.length - 1; i++) {
                group = this.sortBuildingsByScores(group, "SubmarketRank");
            }

			for (let i = 0; i < group.length; i++) {
				results[results.length] = group[i];
			}
        }

        return buildings;
	}

	private sortBuildingsByDistrict(buildings: any) {
		const results: any[] = [];

		const groupedByDistrict = buildings.reduce((acc: any, building: any) => {
			const districtKey = building.district || "null";
			if (!acc[districtKey]) {
				acc[districtKey] = [];
			}
			acc[districtKey].push(building);
			return acc;
		}, {});
	

        for (const key in groupedByDistrict) {
            var group = groupedByDistrict[key];

            for (let i = 0; i < group.length - 1; i++) {
                group = this.sortBuildingsByScores(group, "DistrictRank");
            }

			for (let i = 0; i < group.length; i++) {
				results[results.length] = group[i];
			}
        }

        return buildings;
	}

    private sortBuildingsByScores(buildings: any, rankProperty: string) {

        const sortBuildingsNumeric = (buildingsToSort: any, score: string, propertyName: string): any => {
            buildingsToSort.sort((b, a)=> {
                let sorted = Number(a[score]) - Number(b[score]);
                return sorted;
            });
    
            for (let i = 0; i < buildingsToSort.length; i++) {
                buildingsToSort[i][score + propertyName] = i + 1;
            }
    
            return buildingsToSort;
        }

        buildings = sortBuildingsNumeric(buildings, 'totalScore', rankProperty);
        buildings = sortBuildingsNumeric(buildings, 'constructionQuality', rankProperty);
        buildings = sortBuildingsNumeric(buildings, 'askingRate', rankProperty);
        buildings = sortBuildingsNumeric(buildings, 'commonAreaQuality', rankProperty);
        buildings = sortBuildingsNumeric(buildings, 'onsiteAmenities', rankProperty);
        buildings = sortBuildingsNumeric(buildings, 'sizeStories', rankProperty);
        buildings = sortBuildingsNumeric(buildings, 'ageBonus', rankProperty);
        buildings = sortBuildingsNumeric(buildings, 'locationProminence', rankProperty);
        buildings = sortBuildingsNumeric(buildings, 'relevanceToConsumer', rankProperty);
        buildings = sortBuildingsNumeric(buildings, 'locationConvenience', rankProperty);
        buildings = sortBuildingsNumeric(buildings, 'locationProximity', rankProperty);
        buildings = sortBuildingsNumeric(buildings, 'parkingVisitorExperience', rankProperty);
        buildings = sortBuildingsNumeric(buildings, 'parkingQuantity', rankProperty);
        buildings = sortBuildingsNumeric(buildings, 'parkingQuality', rankProperty);

        return buildings;
    }
}