import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgForm, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from '../../../environments/environment';

@Component({
	selector: 'app-import-excel',
	templateUrl: './import-excel.component.html',
	styleUrls: ['./import-excel.component.css']
})

export class ImportExcelComponent implements OnInit {

	SERVER_URL = environment.BaseURI_API_Account + 'UploadExcelBulkInsert';
	apiUrlMarketExcel = environment.BaseURI_API_Market + 'MarketExcelImport';
	apiUrlLeaseExcel = environment.BaseURI_API_TenantLease + 'LeaseExcelImport';
	apiUrlAbsorption = environment.BaseURI_API_Absorption + 'AbsorptionExcelImport';
	apiUrlComptativeSet = environment.BaseURI_API_Account + 'UploadCompetitiveSetExcel';
	apiUrlSubmarketCode = environment.BaseURI_API_Account + 'OnPostImportForSubmarketCode';

	apiUrlScores = environment.BaseURI_APIExtension + 'building-scores/import';

	uploadForm: FormGroup;
	uploadSuccessAlert: boolean = false;
	uploadErrorMessage: string = null;
	loader: boolean = false;
	edited: boolean = false;
	@ViewChild('myInput')
	myInputVariable: ElementRef;


	constructor(private formBuilder: FormBuilder, private httpClient: HttpClient) { }

	ngOnInit() {
		this.uploadForm = this.formBuilder.group({
			profile: ['']
		});

	}

	onFileSelect(event) {

		if (event.target.files.length > 0) {
			const file = event.target.files[0];
			this.uploadForm.get('profile').setValue(file);
		}
	}

	getExportBuildingScoresURL() {
		return this.apiUrlScores.replace('/import','/export');
	}

	onSubmitMarketExcel() {
		this.uploadSuccessAlert = false;
		this.uploadErrorMessage = null;

		const formData = new FormData();
		
		formData.append('file', this.uploadForm.get('profile').value);
		this.edited = true;
		if (this.uploadForm.get('profile').value != "" && this.uploadForm.get('profile').value != undefined && this.uploadForm.get('profile').value != null) {
			this.loader = true;

			this.httpClient.post<any>(this.apiUrlMarketExcel, formData).subscribe(
				(res) => {
					this.uploadSuccessAlert = true;
					this.myInputVariable.nativeElement.value = "";
					this.loader = false;
				},
				(err) => {
					console.log(err);
					if (err.status == 400) {
						this.uploadErrorMessage = "File not uploaded. " + err.error.message;
						this.myInputVariable.nativeElement.value = "";
						this.loader = false;
					}
					else {
						this.uploadSuccessAlert = true;
						this.myInputVariable.nativeElement.value = "";
						this.loader = false;
					}

				}
			);
		}
		else {
			alert("Please select bulding excel file.");
		}
	}
	onSubmitLeaseExcel() {
		this.uploadSuccessAlert = false;
		this.uploadErrorMessage = null;

		const formData = new FormData();
		
		formData.append('file', this.uploadForm.get('profile').value);
		if (this.uploadForm.get('profile').value != "" && this.uploadForm.get('profile').value != undefined && this.uploadForm.get('profile').value != null) {
			this.loader = true;

			this.httpClient.post<any>(this.apiUrlLeaseExcel, formData).subscribe(
				(res) => {
					this.uploadSuccessAlert = true;
					this.myInputVariable.nativeElement.value = "";
					this.loader = false;
				},
				(err) => {

					if (err.status == 400) {
						this.uploadErrorMessage = "File not uploaded. " + err.error.message;
						this.myInputVariable.nativeElement.value = "";
						this.loader = false;
					}
					else {
						this.uploadSuccessAlert = true;
						this.myInputVariable.nativeElement.value = "";
						this.loader = false;
					}

				}
			);
		}
		else {
			alert("Please select tenant lease excel file.");
		}
		}


	onSubmitAbsorptionExcel() {
		this.uploadSuccessAlert = false;
		this.uploadErrorMessage = null;

		const formData = new FormData();
			
		formData.append('file', this.uploadForm.get('profile').value);
		if (this.uploadForm.get('profile').value != "" && this.uploadForm.get('profile').value != undefined && this.uploadForm.get('profile').value != null) {
			this.loader = true;

			this.httpClient.post<any>(this.apiUrlAbsorption, formData).subscribe(
				(res) => {

					this.uploadSuccessAlert = true;
					this.myInputVariable.nativeElement.value = "";
					this.loader = false;
				},
				(err) => {
					if (err.status == 400) {
						this.uploadErrorMessage = "File not uploaded. " + err.error.message;
						this.myInputVariable.nativeElement.value = "";
						this.loader = false;
					}
					else {
						this.uploadSuccessAlert = true;
						this.myInputVariable.nativeElement.value = "";
						this.loader = false;
					}
				}
			);
		}
		else {
			alert("Please select absorption excel file.");
		}
	}

	onSubmitCompetitiveSetExcel() {
		this.uploadSuccessAlert = false;
		this.uploadErrorMessage = null;

		const formData = new FormData();
		
		formData.append('file', this.uploadForm.get('profile').value);
		if (this.uploadForm.get('profile').value != "" && this.uploadForm.get('profile').value != undefined && this.uploadForm.get('profile').value != null) {
			this.loader = true;
			this.httpClient.post<any>(this.apiUrlComptativeSet, formData).subscribe(
				(res) => {

					this.uploadSuccessAlert = true;
					this.myInputVariable.nativeElement.value = "";
					this.loader = false;
				},
				(err) => {
					if (err.status == 400) {
						this.uploadErrorMessage = "File not uploaded. " + err.error.message;
						this.myInputVariable.nativeElement.value = "";
						this.loader = false;
					}
					else {
						this.uploadSuccessAlert = true;
						this.myInputVariable.nativeElement.value = "";
						this.loader = false;
					}
				}
			);
		} else {
			alert("Please select competitive set excel file.");
		}
	}

	onSubmitSubmarketCode(){
		this.uploadSuccessAlert = false;
		this.uploadErrorMessage = null;

		const formData = new FormData();
		
		formData.append('file', this.uploadForm.get('profile').value);
		if (this.uploadForm.get('profile').value != "" && this.uploadForm.get('profile').value != undefined && this.uploadForm.get('profile').value != null) {
			this.loader = true;
			this.httpClient.post<any>(this.apiUrlSubmarketCode, formData).subscribe(
				(res) => {

					this.uploadSuccessAlert = true;
					this.myInputVariable.nativeElement.value = "";
					this.loader = false;
				},
				(err) => {
					if (err.status == 400) {
						this.uploadErrorMessage = "File not uploaded. " + err.error.message;
						this.myInputVariable.nativeElement.value = "";
						this.loader = false;
					}
					else {
						this.uploadSuccessAlert = true;
						this.myInputVariable.nativeElement.value = "";
						this.loader = false;
					}
				}
			);
		} else {
			alert("Please select submarketcode set excel file.");
		}
	}

	onSubmitScores(){
		this.uploadSuccessAlert = false;
		this.uploadErrorMessage = null;

		const formData = new FormData();
		
		formData.append('file', this.uploadForm.get('profile').value);
		if (this.uploadForm.get('profile').value != "" && this.uploadForm.get('profile').value != undefined && this.uploadForm.get('profile').value != null) {
			this.loader = true;
			this.httpClient.post<any>(this.apiUrlScores, formData).subscribe(
				(res) => {

					this.uploadSuccessAlert = true;
					this.myInputVariable.nativeElement.value = "";
					this.loader = false;
				},
				(err) => {
					if (err.status % 400 < 100) {
						this.uploadErrorMessage = JSON.stringify(err);
						this.myInputVariable.nativeElement.value = "";
						this.loader = false;
					}
					else {
						this.uploadErrorMessage = JSON.stringify(err);
						this.myInputVariable.nativeElement.value = "";
						this.loader = false;
					}
				}
			);
		} else {
			alert("Please select Building Scores Import CSV.");
		}
	}

}
