import { NgModule } from '@angular/core';
import { Paths } from './constants'
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './account/login/login.component';
import { HomeComponent } from './home/home.component';
import { ImportExcelComponent } from './import/import-excel/import-excel.component';
import { OfficeMarketBuildingComponent } from './office-market-building/office-market-building.component';
import { BuildingScoresComponent } from './building-scores/building-scores.component';
import { LayoutComponent } from './layout/layout.component';
import { ForgotPasswordComponent } from './account/forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './account/change-password/change-password.component';
import { UsermanagementComponent } from './account/usermanagement/usermanagement.component';
import { TenantLeasePortalComponent } from '../app/tenant-lease-portal/tenant-lease-portal.component';
import { AuthguardGuard } from '../app/guards/authguard.guard';
import { RenamecompetitivesetsComponent } from './account/renamecompetitivesets/renamecompetitivesets.component';
import { ReportsComponent } from '../app/reports/reports.component';
import { UploadReportsComponent } from '../app/upload-reports/upload-reports.component';
import { AdjustScoreWeightsComponent } from '../app/adjust-score-weights/adjust-score-weights.component';
import { ScoreWeightsComponent } from './account/score-weights/score-weights.component';
import { AssetManagementComponent } from './account/asset-management/asset-management.component';
// import { ImportScoresComponent } from '../app/import-scores/import-scores.component';

const routes: Routes = [
  { path: Paths.Login, component: LoginComponent},
  { path: 'changePassword', component: ChangePasswordComponent },
  { path: 'forgotPassword', component: ForgotPasswordComponent },
  { path: 'RelaodmarketBuilding', redirectTo: '/marketBuilding' },
  {
    path: 'layout', component: LayoutComponent
  },

  { path: '', redirectTo: `/${Paths.Login}`, pathMatch: 'full' },

  {
    path: '', component: LayoutComponent,
    children: [
      {
        path: 'importExcel', component: ImportExcelComponent, canActivate: [AuthguardGuard]
      },
    ]
  },
  {
    path: '', component: LayoutComponent,
    children: [
      {
        path: Paths.Market, component: OfficeMarketBuildingComponent , canActivate: [AuthguardGuard]
      },
    ]
  },
  {
    path: '', component: LayoutComponent,
    children: [
      {
        path: Paths.Users, component: UsermanagementComponent , canActivate: [AuthguardGuard]
      },
    ]
  },
  {
    path: '', component: LayoutComponent,
    children: [
      {
        path: Paths.Lease, component: TenantLeasePortalComponent , canActivate: [AuthguardGuard]
      },
    ]
  },
  {
    path: '', component: LayoutComponent,
    children: [
      {
        path: 'ChangePassword', component: ChangePasswordComponent, canActivate: [AuthguardGuard]
      },
    ]
  },
  {
    path: '', component: LayoutComponent,
    children: [
      {
        path: 'renamecompetitivesets', component: RenamecompetitivesetsComponent, canActivate: [AuthguardGuard]
      },
    ]
  },
  {
    path: '', component: LayoutComponent,
    children: [
      {
        path: 'reports', component: ReportsComponent, canActivate: [AuthguardGuard]
      },
    ]
  },
  {
    path: '', component: LayoutComponent,
    children: [
      {
        path: 'uploadReports', component: UploadReportsComponent, canActivate: [AuthguardGuard]
      },
    ]
  },
  {
    path: '', component: LayoutComponent,
    children: [
      {
        path: Paths.Ratings, component: BuildingScoresComponent , canActivate: [AuthguardGuard]
      },
    ]
  },
  {
    path: '', component: LayoutComponent,
    children: [
      {
        path: Paths.ScoreWeights, component: ScoreWeightsComponent , canActivate: [AuthguardGuard]
      },
    ]
  },
  {
    path: '', component: LayoutComponent,
    children: [
      {
        path: Paths.Home, component: HomeComponent, canActivate: [AuthguardGuard]
      },
    ]
  },
  {
    path: '', component: LayoutComponent,
    children: [
      {
        path: Paths.AssetManagement, component: AssetManagementComponent, canActivate: [AuthguardGuard]
      },
    ]
  },

]
  

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
