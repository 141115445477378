import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UsermanagementService } from '../../services/usermanagement.service';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { Console } from '@angular/core/src/console';

@Component({
  selector: 'app-renamecompetitivesets',
  templateUrl: './renamecompetitivesets.component.html',
  styleUrls: ['./renamecompetitivesets.component.css']
})
export class RenamecompetitivesetsComponent implements OnInit {
  options: any;
  customCompetitiveSetNameList: any;

  constructor(private usermanagement: UsermanagementService) {
  }

  ngOnInit() {
    this.GetRenameCompetitiveSetsList();
  }
  
  onSubmit(id,aliasName) {
    this.usermanagement.UpdateCompetitiveSetCompNameById(id, aliasName).subscribe((res) => {
      this.GetRenameCompetitiveSetsList(); 
      alert("Updated successfully.");
      
    }, error => console.error(error))
  }
  //Get Competitive Set custom names
  GetRenameCompetitiveSetsList() {
    
    this.usermanagement.GetCompetitiveSetCustomNames().subscribe((res) => {
      this.customCompetitiveSetNameList = res;
      //console.log(res);
    }, error => console.error(error))
  }

}
