import { Component, NgZone} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  showHeader: boolean = true;
  constructor(private route: Router) {

  }
  ngOnInit() {
   
    if (this.route.url == '/' || this.route.url == '/login') {
      this.showHeader = false;
    }
  
    //else {
    //  this.showHeader = true;
    //}

  }
  displayCounter(event) {
     alert(event);
  }
}
