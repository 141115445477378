import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpClientModule } from "@angular/common/http";
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class UsermanagementService {

  BaseURI = environment.BaseURI_API_Account;

  constructor(private http: HttpClient) { }
  GetUserList() {
    //return this.http.post(this.BaseURI + 'GetSubMarketList', {  });
    return this.http.get(this.BaseURI + 'GetUserList');
  }

  IsChangeStatus(Id) {
    return this.http.post(this.BaseURI + 'StatusChangeIsActive?' + 'Id=' + Id, " ");
  }

  AddRemoveAdminRole(Id, userRoleName) {
    return this.http.get(this.BaseURI + 'AddRemoveAdminRole?Id=' + Id+'&userRole=' + userRoleName);
  }

  PortalAccessPermission(UserId, IsBuildingPortalAccess, IsLeasePortalAccess, IsReportAccess, IsCompetitiveSet1Access, IsCompetitiveSet2Access, IsCompetitiveSet3Access, IsCompetitiveSet4Access, IsCompetitiveSet5Access, IsCompetitiveSet6Access, IsCompetitiveSet7Access, IsCompetitiveSet8Access, IsCompetitiveSet9Access, IsCompetitiveSet10Access, IsCompetitiveSet11Access, IsCompetitiveSet12Access, IsCompetitiveSet13Access, IsCompetitiveSet14Access, IsCompetitiveSet15Access, IsCompetitiveSet16Access, IsCompetitiveSet17Access, IsCompetitiveSet18Access, IsCompetitiveSet19Access, IsCompetitiveSet20Access, IsCompetitiveSet21Access, IsCompetitiveSet22Access, IsCompetitiveSet23Access, IsCompetitiveSet24Access, IsCompetitiveSet25Access, IsCompetitiveSet26Access, IsCompetitiveSet27Access, IsCompetitiveSet28Access, IsCompetitiveSet29Access, IsCompetitiveSet30Access, IsCompetitiveSet31Access, IsCompetitiveSet32Access, IsCompetitiveSet33Access, IsCompetitiveSet34Access, IsCompetitiveSet35Access, IsCompetitiveSet36Access, IsCompetitiveSet37Access, IsCompetitiveSet38Access, IsCompetitiveSet39Access, IsCompetitiveSet40Access) {
    return this.http.post(this.BaseURI + 'PortalAccessPermissionType', { UserId, IsBuildingPortalAccess, IsLeasePortalAccess, IsReportAccess, IsCompetitiveSet1Access, IsCompetitiveSet2Access, IsCompetitiveSet3Access, IsCompetitiveSet4Access, IsCompetitiveSet5Access, IsCompetitiveSet6Access, IsCompetitiveSet7Access, IsCompetitiveSet8Access, IsCompetitiveSet9Access, IsCompetitiveSet10Access, IsCompetitiveSet11Access, IsCompetitiveSet12Access, IsCompetitiveSet13Access, IsCompetitiveSet14Access, IsCompetitiveSet15Access, IsCompetitiveSet16Access, IsCompetitiveSet17Access, IsCompetitiveSet18Access, IsCompetitiveSet19Access, IsCompetitiveSet20Access, IsCompetitiveSet21Access, IsCompetitiveSet22Access, IsCompetitiveSet23Access, IsCompetitiveSet24Access, IsCompetitiveSet25Access, IsCompetitiveSet26Access, IsCompetitiveSet27Access, IsCompetitiveSet28Access, IsCompetitiveSet29Access, IsCompetitiveSet30Access, IsCompetitiveSet31Access, IsCompetitiveSet32Access, IsCompetitiveSet33Access, IsCompetitiveSet34Access, IsCompetitiveSet35Access, IsCompetitiveSet36Access, IsCompetitiveSet37Access, IsCompetitiveSet38Access, IsCompetitiveSet39Access, IsCompetitiveSet40Access });
  }

  GetUserPermissions(UserId) {
    
    return this.http.get(this.BaseURI + 'GetUserPermissions?' + 'userId=' + UserId);
  }
  //GetCompetitiveSetCustomNames
  GetCompetitiveSetCustomNames() {
    return this.http.get(this.BaseURI + 'GetCompetitiveSetCustomNames');
  }
  UpdateCompetitiveSetCompNameById(id, aliasName) {
    return this.http.get(this.BaseURI + 'UpdateCompetitiveSetCompNameById?id=' + id + '&compName=' + aliasName);
  }
}
