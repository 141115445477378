import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-alert-model-popup',
  templateUrl: './alert-model-popup.component.html',
  styleUrls: ['./alert-model-popup.component.css']
})
export class AlertModelPopupComponent implements OnInit {
  @Input('getShowmodal') getShowmodal;
  @Output('Rmodal') Rmodal = new EventEmitter();
  @Input('bindModal') bindModal;
  @Output('Cmodal') Cmodal = new EventEmitter();
  removemodal: boolean;
  BindResult: any;
  constructor() { }

  ngOnInit() {
    this.BindResult = this.bindModal
  }


  RemoveModal() {
    this.removemodal = this.getShowmodal;
    this.removemodal = !this.removemodal;
    this.Rmodal.emit(this.removemodal);
  }


  DeActvatedModel() {
    this.RemoveModal();
    this.BindResult = this.bindModal;
    this.Cmodal.emit(this.BindResult);
  }

}
