import {Component, OnInit } from '@angular/core';
import { UsermanagementService } from '../../services/usermanagement.service';
import { from } from 'rxjs';
import { Router } from '@angular/router';
import { UserDetail } from '../../entity/UserDetail.entity';
import { validateConfig } from '@angular/router/src/config';
import { Console } from '@angular/core/src/console';
declare var $: any;

@Component({
  selector: 'app-usermanagement',
  templateUrl: './usermanagement.component.html',
  styleUrls: ['./usermanagement.component.css']
})
export class UsermanagementComponent implements OnInit {

  ans: any;
  activateDeactivateUserMsg: string;
  portalAccessText: any;
  showModalPortalPermission: boolean = false;
  showModal: boolean = false;
  Id: number;
  userRoleName: string;
  rolePermission: string;
  isBuilding: boolean = false;
  isTenant: boolean = false;
  isExport: boolean = false;
  isSet1: boolean = false;
  isSet2: boolean = false;
  isSet3: boolean = false;
  isSet4: boolean = false;
  isSet5: boolean = false;
  isSet6: boolean = false;
  isSet7: boolean = false;
  isSet8: boolean = false;
  isSet9: boolean = false;
  isSet10: boolean = false;
  isSet11: boolean = false;
  isSet12: boolean = false;
  isSet13: boolean = false;
  isSet14: boolean = false;
  isSet15: boolean = false;
  isSet16: boolean = false;
  isSet17: boolean = false;
  isSet18: boolean = false;
  isSet19: boolean = false;
  isSet20: boolean = false;
  isSet21: boolean = false;
  isSet22: boolean = false;
  isSet23: boolean = false;
  isSet24: boolean = false;
  isSet25: boolean = false;
  isSet26: boolean = false;
  isSet27: boolean = false;
  isSet28: boolean = false;
  isSet29: boolean = false;
  isSet30: boolean = false;
  isSet31: boolean = false;
  isSet32: boolean = false;
  isSet33: boolean = false;
  isSet34: boolean = false;
  isSet35: boolean = false;
  isSet36: boolean = false;
  isSet37: boolean = false;
  isSet38: boolean = false;
  isSet39: boolean = false;
  isSet40: boolean = false;

  isSet1Name: string = 'My Test';
  isSet2Name: string = '';
  isSet3Name: string = '';
  isSet4Name: string = '';
  isSet5Name: string = '';
  isSet6Name: string = '';
  isSet7Name: string = '';
  isSet8Name: string = '';
  isSet9Name: string = '';
  isSet10Name: string = '';
  isSet11Name: string = '';
  isSet12Name: string = '';
  isSet13Name: string = '';
  isSet14Name: string = '';
  isSet15Name: string = '';
  isSet16Name: string = '';
  isSet17Name: string = '';
  isSet18Name: string = '';
  isSet19Name: string = '';
  isSet20Name: string = '';
  isSet21Name: string = '';
  isSet22Name: string = '';
  isSet23Name: string = '';
  isSet24Name: string = '';
  isSet25Name: string = '';
  isSet26Name: string = '';
  isSet27Name: string = '';
  isSet28Name: string = '';
  isSet29Name: string = '';
  isSet30Name: string = '';
  isSet31Name: string = '';
  isSet32Name: string = '';
  isSet33Name: string = '';
  isSet34Name: string = '';
  isSet35Name: string = '';
  isSet36Name: string = '';
  isSet37Name: string = '';
  isSet38Name: string = '';
  isSet39Name: string = '';
  isSet40Name: string = '';
  constructor(private usermanagement: UsermanagementService, private route: Router) {
  }
  userdetails: UserDetail[];
  cols: any[];
  person: any[];
  userList: [];
  userPermission: any;
  customCompetitiveSetName: any;

  options = [
    { name: 'Building Portal', value: '1', checked: false },
    { name: 'Tenant Portal', value: '2', checked: false },
    { name: 'Report', value: '3', checked: false },
    { name: 'Competitive Set 1', value: '4', checked: false },
    { name: 'Competitive Set 2', value: '5', checked: false },
    { name: 'Competitive Set 3', value: '6', checked: false },
    { name: 'Competitive Set 4', value: '7', checked: false },
    { name: 'Competitive Set 5', value: '8', checked: false },
    { name: 'Competitive Set 6', value: '9', checked: false },
    { name: 'Competitive Set 7', value: '10', checked: false },
    { name: 'Competitive Set 8', value: '11', checked: false },
    { name: 'Competitive Set 9', value: '12', checked: false },
    { name: 'Competitive Set 10', value: '13', checked: false },
    { name: 'Competitive Set 11', value: '14', checked: false },
    { name: 'Competitive Set 12', value: '15', checked: false },
    { name: 'Competitive Set 13', value: '16', checked: false },
    { name: 'Competitive Set 14', value: '17', checked: false },
    { name: 'Competitive Set 15', value: '18', checked: false },
    { name: 'Competitive Set 16', value: '19', checked: false },
    { name: 'Competitive Set 17', value: '20', checked: false },
    { name: 'Competitive Set 18', value: '21', checked: false },
    { name: 'Competitive Set 19', value: '22', checked: false },
    { name: 'Competitive Set 20', value: '23', checked: false },
    { name: 'Competitive Set 21', value: '24', checked: false },
    { name: 'Competitive Set 22', value: '25', checked: false },
    { name: 'Competitive Set 23', value: '26', checked: false },
    { name: 'Competitive Set 24', value: '27', checked: false },
    { name: 'Competitive Set 25', value: '28', checked: false },
    { name: 'Competitive Set 26', value: '29', checked: false },
    { name: 'Competitive Set 27', value: '30', checked: false },
    { name: 'Competitive Set 28', value: '31', checked: false },
    { name: 'Competitive Set 29', value: '32', checked: false },
    { name: 'Competitive Set 30', value: '33', checked: false },
    { name: 'Competitive Set 31', value: '34', checked: false },
    { name: 'Competitive Set 32', value: '35', checked: false },
    { name: 'Competitive Set 33', value: '36', checked: false },
    { name: 'Competitive Set 34', value: '37', checked: false },
    { name: 'Competitive Set 35', value: '38', checked: false },
    { name: 'Competitive Set 36', value: '39', checked: false },
    { name: 'Competitive Set 37', value: '40', checked: false },
    { name: 'Competitive Set 38', value: '41', checked: false },
    { name: 'Competitive Set 39', value: '42', checked: false },
    { name: 'Competitive Set 40', value: '43', checked: false }
  ];
  optionsMap = {
    'Building Portal': false,
    'Tenant Portal': false,
    'Report': false,
    'Competitive Set 1': false,
    'Competitive Set 2': false,
    'Competitive Set 3': false,
    'Competitive Set 4': false,
    'Competitive Set 5': false,
    'Competitive Set 6': false,
    'Competitive Set 7': false,
    'Competitive Set 8': false,
    'Competitive Set 9': false,
    'Competitive Set 10': false,
    'Competitive Set 11': false,
    'Competitive Set 12': false,
    'Competitive Set 13': false,
    'Competitive Set 14': false,
    'Competitive Set 15': false,
    'Competitive Set 16': false,
    'Competitive Set 17': false,
    'Competitive Set 18': false,
    'Competitive Set 19': false,
    'Competitive Set 20': false,
    'Competitive Set 21': false,
    'Competitive Set 22': false,
    'Competitive Set 23': false,
    'Competitive Set 24': false,
    'Competitive Set 25': false,
    'Competitive Set 26': false,
    'Competitive Set 27': false,
    'Competitive Set 28': false,
    'Competitive Set 29': false,
    'Competitive Set 30': false,
    'Competitive Set 31': false,
    'Competitive Set 32': false,
    'Competitive Set 33': false,
    'Competitive Set 34': false,
    'Competitive Set 35': false,
    'Competitive Set 36': false,
    'Competitive Set 37': false,
    'Competitive Set 38': false,
    'Competitive Set 39': false,
    'Competitive Set 40': false
  };
  isBuildingPortal: boolean = false;
  isTenantPortal: boolean = false;
  isReportPortal: boolean = false;
  isCompetitiveSet1: boolean = false;
  isCompetitiveSet2: boolean = false;
  isCompetitiveSet3: boolean = false;
  isCompetitiveSet4: boolean = false;
  isCompetitiveSet5: boolean = false;
  isCompetitiveSet6: boolean = false;
  isCompetitiveSet7: boolean = false;
  isCompetitiveSet8: boolean = false;
  isCompetitiveSet9: boolean = false;
  isCompetitiveSet10: boolean = false;
  isCompetitiveSet11: boolean = false;
  isCompetitiveSet12: boolean = false;
  isCompetitiveSet13: boolean = false;
  isCompetitiveSet14: boolean = false;
  isCompetitiveSet15: boolean = false;
  isCompetitiveSet16: boolean = false;
  isCompetitiveSet17: boolean = false;
  isCompetitiveSet18: boolean = false;
  isCompetitiveSet19: boolean = false;
  isCompetitiveSet20: boolean = false;
  isCompetitiveSet21: boolean = false;
  isCompetitiveSet22: boolean = false;
  isCompetitiveSet23: boolean = false;
  isCompetitiveSet24: boolean = false;
  isCompetitiveSet25: boolean = false;
  isCompetitiveSet26: boolean = false;
  isCompetitiveSet27: boolean = false;
  isCompetitiveSet28: boolean = false;
  isCompetitiveSet29: boolean = false;
  isCompetitiveSet30: boolean = false;
  isCompetitiveSet31: boolean = false;
  isCompetitiveSet32: boolean = false;
  isCompetitiveSet33: boolean = false;
  isCompetitiveSet34: boolean = false;
  isCompetitiveSet35: boolean = false;
  isCompetitiveSet36: boolean = false;
  isCompetitiveSet37: boolean = false;
  isCompetitiveSet38: boolean = false;
  isCompetitiveSet39: boolean = false;
  isCompetitiveSet40: boolean = false;
  optionsChecked = [];

  ngOnInit() {
    
    $('[data-toggle="tooltip"]').tooltip();
    this.cols = [
      { field: 'id', header: 'Id', },
      { field: 'userName', header: 'User Name' },
      { field: 'email', header: 'Email' },
      { field: 'phoneNumber', header: 'Phone Number' },
      { field: 'companyName', header: 'Company Name' },
      { field: 'role', header: 'User Role' },
      { field: 'isActive', header: 'IsActive' },

    ];

    this.GetUserDetailList();

  }


  GetUserDetailList() {
    this.usermanagement.GetUserList().subscribe(
      (res: any) => {
      
        this.userdetails = res;
        console.log(res);
      },
      err => {

        console.log("error section.");
      }
    )
  }


  RemoveModal(val) {
    ;
    this.showModal = val;
  }

  DeActvatedModel(result) {
    
    this.ans = result;
    if (this.ans) {
      this.usermanagement.IsChangeStatus(this.Id).subscribe((data) => {
        this.userdetails = null;
        alert(this.activateDeactivateUserMsg);
        this.GetUserDetailList();
      }, error => console.error(error))
    }
  }

  ChangeStatus(event, Id, IsActive, UserName) {
    
    this.showModal = true;
    this.Id = Id;
    if (IsActive == true) {
      this.activateDeactivateUserMsg = "User deactivated successfully.";
      this.ans = "Are you sure you want to deactivate user : " + UserName;
    
    }
    else {
      this.activateDeactivateUserMsg = "User activated successfully.";
      this.ans = "Are you sure you want to activate user : " + UserName;
      
    }
  }

   // End Activate and deactivate user model popup functionality

  //Start Portal accesss to the user model popup functionality 
  RemoveModalPortalPermission(val) {
    this.showModalPortalPermission = val;
  }

  updateCheckedOptions(option, event) {
    
  }

  PortalPermission(result) {
    this.portalAccessText = result;
   // for (let i = 0; i < this.options.length; i++) {
      this.isBuildingPortal = this.options[0].checked;
      this.isTenantPortal = this.options[1].checked;
      this.isReportPortal = this.options[2].checked;
      this.isCompetitiveSet1 = this.options[3].checked;
      this.isCompetitiveSet2 = this.options[4].checked;
      this.isCompetitiveSet3 = this.options[5].checked;
      this.isCompetitiveSet4 = this.options[6].checked;
      this.isCompetitiveSet5 = this.options[7].checked;
      this.isCompetitiveSet6 = this.options[8].checked;
      this.isCompetitiveSet7 = this.options[9].checked;
      this.isCompetitiveSet8 = this.options[10].checked;
      this.isCompetitiveSet9 = this.options[11].checked;
      this.isCompetitiveSet10= this.options[12].checked;
      this.isCompetitiveSet11= this.options[13].checked;
      this.isCompetitiveSet12= this.options[14].checked;
      this.isCompetitiveSet13= this.options[15].checked;
      this.isCompetitiveSet14= this.options[16].checked;
      this.isCompetitiveSet15= this.options[17].checked;
      this.isCompetitiveSet16= this.options[18].checked;
      this.isCompetitiveSet17= this.options[19].checked;
      this.isCompetitiveSet18= this.options[20].checked;
      this.isCompetitiveSet19= this.options[21].checked;
      this.isCompetitiveSet20= this.options[22].checked;
      this.isCompetitiveSet21= this.options[23].checked;
      this.isCompetitiveSet22= this.options[24].checked;
      this.isCompetitiveSet23= this.options[25].checked;
      this.isCompetitiveSet24= this.options[26].checked;
      this.isCompetitiveSet25= this.options[27].checked;
      this.isCompetitiveSet26= this.options[28].checked;
      this.isCompetitiveSet27= this.options[29].checked;
      this.isCompetitiveSet28= this.options[30].checked;
      this.isCompetitiveSet29= this.options[31].checked;
      this.isCompetitiveSet30= this.options[32].checked;
      this.isCompetitiveSet31= this.options[33].checked;
      this.isCompetitiveSet32= this.options[34].checked;
      this.isCompetitiveSet33= this.options[35].checked;
      this.isCompetitiveSet34= this.options[36].checked;
      this.isCompetitiveSet35= this.options[37].checked;
      this.isCompetitiveSet36= this.options[38].checked;
      this.isCompetitiveSet37= this.options[39].checked;
      this.isCompetitiveSet38= this.options[40].checked;
      this.isCompetitiveSet39= this.options[41].checked;
      this.isCompetitiveSet40 = this.options[42].checked;
    //}
    if (this.portalAccessText) {
      this.usermanagement.PortalAccessPermission(this.Id, this.isBuildingPortal, this.isTenantPortal, this.isReportPortal, this.isCompetitiveSet1, this.isCompetitiveSet2, this.isCompetitiveSet3, this.isCompetitiveSet4, this.isCompetitiveSet5, this.isCompetitiveSet6, this.isCompetitiveSet7, this.isCompetitiveSet8, this.isCompetitiveSet9, this.isCompetitiveSet10, this.isCompetitiveSet11, this.isCompetitiveSet12, this.isCompetitiveSet13, this.isCompetitiveSet14, this.isCompetitiveSet15, this.isCompetitiveSet16, this.isCompetitiveSet17, this.isCompetitiveSet18, this.isCompetitiveSet19, this.isCompetitiveSet20, this.isCompetitiveSet21, this.isCompetitiveSet22, this.isCompetitiveSet23, this.isCompetitiveSet24, this.isCompetitiveSet25, this.isCompetitiveSet26, this.isCompetitiveSet27, this.isCompetitiveSet28, this.isCompetitiveSet29, this.isCompetitiveSet30, this.isCompetitiveSet31, this.isCompetitiveSet32, this.isCompetitiveSet33, this.isCompetitiveSet34, this.isCompetitiveSet35, this.isCompetitiveSet36, this.isCompetitiveSet37, this.isCompetitiveSet38, this.isCompetitiveSet39, this.isCompetitiveSet40).subscribe((data) => {
        this.userdetails = null;
        alert("Permission updated successfully.");
        this.GetUserDetailList();                                                                                                                                         
      }, error => console.error(error))                                                                                                                                   
    }                                                                                                                                                                    
  }                                                                                                                                                                      
                                                                                                                                                                         
  LoadUserPermissions(event, Id, IsActive, UserName) {                                                                                                                   
    this.Id = Id;                                                                                                                                                        
     
    this.usermanagement.GetUserPermissions(this.Id).subscribe((res) => {                                                                                                 
                                                                                                                                                                         
      this.userPermission = res;
      console.log(res);
      for (let i = 0; i < this.userPermission.length; i++) {                                                                                                             
        if (res[i]['claimType'] == 'IsBuildingPortalAccess') {                                                                                                           
          this.isBuilding = JSON.parse(res[i]['claimValue']);                                                                                                            
        }                                                                                                                                                                
        else if (res[i]['claimType'] == 'IsTenantPortalAccess') {                                                                                                        
          this.isTenant = JSON.parse(res[i]['claimValue']);                                                                                                              
        }                                                                                                                                                                
        else if (res[i]['claimType'] == 'IsReportAccess') {                                                                                                              
          this.isExport = JSON.parse(res[i]['claimValue']);                                                                                                              
        }                                                                                                                                                                
        else if (res[i]['claimType'] == 'CompetitiveSet1') {                                                                                                     
          this.isSet1 = JSON.parse(res[i]['claimValue']);                                                                                                                
        }
        else if (res[i]['claimType'] == 'CompetitiveSet2') {
          this.isSet2 = JSON.parse(res[i]['claimValue']);
        }
        else if (res[i]['claimType'] == 'CompetitiveSet3') {
          this.isSet3 = JSON.parse(res[i]['claimValue']);
        }
        else if (res[i]['claimType'] == 'CompetitiveSet4') {
          this.isSet4 = JSON.parse(res[i]['claimValue']);
        }
        else if (res[i]['claimType'] == 'CompetitiveSet5') {
          this.isSet5 = JSON.parse(res[i]['claimValue']);
        }
        else if (res[i]['claimType'] == 'CompetitiveSet6') {
          this.isSet6 = JSON.parse(res[i]['claimValue']);
        }
        else if (res[i]['claimType'] == 'CompetitiveSet7') {
          this.isSet7 = JSON.parse(res[i]['claimValue']);
        }
        else if (res[i]['claimType'] == 'CompetitiveSet8') {
          this.isSet8 = JSON.parse(res[i]['claimValue']);
        }
        else if (res[i]['claimType'] == 'CompetitiveSet9') {
          this.isSet9 = JSON.parse(res[i]['claimValue']);
        }
        else if (res[i]['claimType'] == 'CompetitiveSet10') {
          this.isSet10 = JSON.parse(res[i]['claimValue']);
        }
        else if (res[i]['claimType'] == 'CompetitiveSet11') {
          this.isSet11 = JSON.parse(res[i]['claimValue']);
        }
        else if (res[i]['claimType'] == 'CompetitiveSet12') {
          this.isSet12 = JSON.parse(res[i]['claimValue']);
        }
        else if (res[i]['claimType'] == 'CompetitiveSet13') {
          this.isSet13 = JSON.parse(res[i]['claimValue']);
        }
        else if (res[i]['claimType'] == 'CompetitiveSet14') {
          this.isSet14 = JSON.parse(res[i]['claimValue']);
        }
        else if (res[i]['claimType'] == 'CompetitiveSet15') {
          this.isSet15 = JSON.parse(res[i]['claimValue']);
        }
        else if (res[i]['claimType'] == 'CompetitiveSet16') {
          this.isSet16 = JSON.parse(res[i]['claimValue']);
        }
        else if (res[i]['claimType'] == 'CompetitiveSet17') {
          this.isSet17 = JSON.parse(res[i]['claimValue']);
        }
        else if (res[i]['claimType'] == 'CompetitiveSet18') {
          this.isSet18 = JSON.parse(res[i]['claimValue']);
        }
        else if (res[i]['claimType'] == 'CompetitiveSet19') {
          this.isSet19 = JSON.parse(res[i]['claimValue']);
        }
        else if (res[i]['claimType'] == 'CompetitiveSet20') {
          this.isSet20 = JSON.parse(res[i]['claimValue']);
        }
        else if (res[i]['claimType'] == 'CompetitiveSet21') {
          this.isSet21 = JSON.parse(res[i]['claimValue']);
        }
        else if (res[i]['claimType'] == 'CompetitiveSet22') {
          this.isSet22 = JSON.parse(res[i]['claimValue']);
        }
        else if (res[i]['claimType'] == 'CompetitiveSet23') {
          this.isSet23 = JSON.parse(res[i]['claimValue']);
        }
        else if (res[i]['claimType'] == 'CompetitiveSet24') {
          this.isSet24 = JSON.parse(res[i]['claimValue']);
        }
        else if (res[i]['claimType'] == 'CompetitiveSet25') {
          this.isSet25 = JSON.parse(res[i]['claimValue']);
        }
        else if (res[i]['claimType'] == 'CompetitiveSet26') {
          this.isSet26 = JSON.parse(res[i]['claimValue']);
        }
        else if (res[i]['claimType'] == 'CompetitiveSet27') {
          this.isSet27 = JSON.parse(res[i]['claimValue']);
        }
        else if (res[i]['claimType'] == 'CompetitiveSet28') {
          this.isSet28 = JSON.parse(res[i]['claimValue']);
        }
        else if (res[i]['claimType'] == 'CompetitiveSet29') {
          this.isSet29 = JSON.parse(res[i]['claimValue']);
        }
        else if (res[i]['claimType'] == 'CompetitiveSet30') {
          this.isSet30 = JSON.parse(res[i]['claimValue']);
        }
        else if (res[i]['claimType'] == 'CompetitiveSet31') {
          this.isSet31 = JSON.parse(res[i]['claimValue']);
        }
        else if (res[i]['claimType'] == 'CompetitiveSet32') {
          this.isSet32 = JSON.parse(res[i]['claimValue']);
        }
        else if (res[i]['claimType'] == 'CompetitiveSet33') {
          this.isSet33 = JSON.parse(res[i]['claimValue']);
        }
        else if (res[i]['claimType'] == 'CompetitiveSet34') {
          this.isSet34 = JSON.parse(res[i]['claimValue']);
        }
        else if (res[i]['claimType'] == 'CompetitiveSet35') {
          this.isSet35 = JSON.parse(res[i]['claimValue']);
        }
        else if (res[i]['claimType'] == 'CompetitiveSet36') {
          this.isSet36 = JSON.parse(res[i]['claimValue']);
        }
        else if (res[i]['claimType'] == 'CompetitiveSet37') {
          this.isSet37 = JSON.parse(res[i]['claimValue']);
        }
        else if (res[i]['claimType'] == 'CompetitiveSet38') {
          this.isSet38 = JSON.parse(res[i]['claimValue']);
        }
        else if (res[i]['claimType'] == 'CompetitiveSet39') {
          this.isSet39 = JSON.parse(res[i]['claimValue']);
        }
        else if (res[i]['claimType'] == 'CompetitiveSet40') {
          this.isSet40 = JSON.parse(res[i]['claimValue']);
        }
      }                                                                                                                                                                  

      //Get Competitive Set custom names
      this.usermanagement.GetCompetitiveSetCustomNames().subscribe((res) => {
        console.log(res);
        this.customCompetitiveSetName = res;
        for (let i = 0; i < 40; i++) {
          if (res[i]['name'] == 'CompetitiveSet1') { this.isSet1Name = res[i]['aliasName']; }
          else if (res[i]['name'] == 'CompetitiveSet2') { this.isSet2Name = res[i]['aliasName']; }
          else if (res[i]['name'] == 'CompetitiveSet3') { this.isSet3Name = res[i]['aliasName']; }
          else if (res[i]['name'] == 'CompetitiveSet4') { this.isSet4Name = res[i]['aliasName']; }
          else if (res[i]['name'] == 'CompetitiveSet5') { this.isSet5Name = res[i]['aliasName']; }
          else if (res[i]['name'] == 'CompetitiveSet6') { this.isSet6Name = res[i]['aliasName']; }
          else if (res[i]['name'] == 'CompetitiveSet7') { this.isSet7Name = res[i]['aliasName']; }
          else if (res[i]['name'] == 'CompetitiveSet8') { this.isSet8Name = res[i]['aliasName']; }
          else if (res[i]['name'] == 'CompetitiveSet9') { this.isSet9Name = res[i]['aliasName']; }
          else if (res[i]['name'] == 'CompetitiveSet10') { this.isSet10Name = res[i]['aliasName']; }

          else if (res[i]['name'] == 'CompetitiveSet11') { this.isSet11Name = res[i]['aliasName']; }
          else if (res[i]['name'] == 'CompetitiveSet12') { this.isSet12Name = res[i]['aliasName']; }
          else if (res[i]['name'] == 'CompetitiveSet13') { this.isSet13Name = res[i]['aliasName']; }
          else if (res[i]['name'] == 'CompetitiveSet14') { this.isSet14Name = res[i]['aliasName']; }
          else if (res[i]['name'] == 'CompetitiveSet15') { this.isSet15Name = res[i]['aliasName']; }
          else if (res[i]['name'] == 'CompetitiveSet16') { this.isSet16Name = res[i]['aliasName']; }
          else if (res[i]['name'] == 'CompetitiveSet17') { this.isSet17Name = res[i]['aliasName']; }
          else if (res[i]['name'] == 'CompetitiveSet18') { this.isSet18Name = res[i]['aliasName']; }
          else if (res[i]['name'] == 'CompetitiveSet19') { this.isSet19Name = res[i]['aliasName']; }
          else if (res[i]['name'] == 'CompetitiveSet20') { this.isSet20Name = res[i]['aliasName']; }
          else if (res[i]['name'] == 'CompetitiveSet21') { this.isSet21Name = res[i]['aliasName']; }
          else if (res[i]['name'] == 'CompetitiveSet22') { this.isSet22Name = res[i]['aliasName']; }
          else if (res[i]['name'] == 'CompetitiveSet23') { this.isSet23Name = res[i]['aliasName']; }
          else if (res[i]['name'] == 'CompetitiveSet24') { this.isSet24Name = res[i]['aliasName']; }
          else if (res[i]['name'] == 'CompetitiveSet25') { this.isSet25Name = res[i]['aliasName']; }
          else if (res[i]['name'] == 'CompetitiveSet26') { this.isSet26Name = res[i]['aliasName']; }
          else if (res[i]['name'] == 'CompetitiveSet27') { this.isSet27Name = res[i]['aliasName']; }
          else if (res[i]['name'] == 'CompetitiveSet28') { this.isSet28Name = res[i]['aliasName']; }
          else if (res[i]['name'] == 'CompetitiveSet29') { this.isSet29Name = res[i]['aliasName']; }
          else if (res[i]['name'] == 'CompetitiveSet30') { this.isSet30Name = res[i]['aliasName']; }
          else if (res[i]['name'] == 'CompetitiveSet31') { this.isSet31Name = res[i]['aliasName']; }
          else if (res[i]['name'] == 'CompetitiveSet32') { this.isSet32Name = res[i]['aliasName']; }
          else if (res[i]['name'] == 'CompetitiveSet33') { this.isSet33Name = res[i]['aliasName']; }
          else if (res[i]['name'] == 'CompetitiveSet34') { this.isSet34Name = res[i]['aliasName']; }
          else if (res[i]['name'] == 'CompetitiveSet35') { this.isSet35Name = res[i]['aliasName']; }
          else if (res[i]['name'] == 'CompetitiveSet36') { this.isSet36Name = res[i]['aliasName']; }
          else if (res[i]['name'] == 'CompetitiveSet37') { this.isSet37Name = res[i]['aliasName']; }
          else if (res[i]['name'] == 'CompetitiveSet38') { this.isSet38Name = res[i]['aliasName']; }
          else if (res[i]['name'] == 'CompetitiveSet39') { this.isSet39Name = res[i]['aliasName']; }
          else if (res[i]['name'] == 'CompetitiveSet40') { this.isSet40Name = res[i]['aliasName']; }
        }
         this.options = [

            { name: 'Building Portal', value: '1', checked: this.isBuilding },
            { name: 'Tenant Portal', value: '2', checked: this.isTenant },
            { name: 'Report', value: '3', checked: this.isExport },
            { name: this.isSet1Name, value: '4', checked: this.isSet1 },
            { name: this.isSet2Name, value: '5', checked: this.isSet2 },
            { name: this.isSet3Name, value: '6', checked: this.isSet3 },
            { name: this.isSet4Name, value: '7', checked: this.isSet4 },
            { name: this.isSet5Name, value: '8', checked: this.isSet5 },
            { name: this.isSet6Name, value: '9', checked: this.isSet6 },
            { name: this.isSet7Name, value: '10', checked: this.isSet7 },
            { name: this.isSet8Name, value: '11', checked: this.isSet8 },
            { name: this.isSet9Name, value: '12', checked: this.isSet9 },
            { name: this.isSet10Name, value: '13', checked: this.isSet10 },
            { name: this.isSet11Name, value: '14', checked: this.isSet11 },
            { name: this.isSet12Name, value: '15', checked: this.isSet12 },
            { name: this.isSet13Name, value: '16', checked: this.isSet13 },
            { name: this.isSet14Name, value: '17', checked: this.isSet14 },
            { name: this.isSet15Name, value: '18', checked: this.isSet15 },
            { name: this.isSet16Name, value: '19', checked: this.isSet16 },
            { name: this.isSet17Name, value: '20', checked: this.isSet17 },
            { name: this.isSet18Name, value: '21', checked: this.isSet18 },
            { name: this.isSet19Name, value: '22', checked: this.isSet19 },
            { name: this.isSet20Name, value: '23', checked: this.isSet20 },
            { name: this.isSet21Name, value: '24', checked: this.isSet21 },
            { name: this.isSet22Name, value: '25', checked: this.isSet22 },
            { name: this.isSet23Name, value: '26', checked: this.isSet23 },
            { name: this.isSet24Name, value: '27', checked: this.isSet24 },
            { name: this.isSet25Name, value: '28', checked: this.isSet25 },
            { name: this.isSet26Name, value: '29', checked: this.isSet26 },
            { name: this.isSet27Name, value: '30', checked: this.isSet27 },
            { name: this.isSet28Name, value: '31', checked: this.isSet28 },
            { name: this.isSet29Name, value: '32', checked: this.isSet29 },
            { name: this.isSet30Name, value: '33', checked: this.isSet30 },
            { name: this.isSet31Name, value: '34', checked: this.isSet31 },
            { name: this.isSet32Name, value: '35', checked: this.isSet32 },
            { name: this.isSet33Name, value: '36', checked: this.isSet33 },
            { name: this.isSet34Name, value: '37', checked: this.isSet34 },
            { name: this.isSet35Name, value: '38', checked: this.isSet35 },
            { name: this.isSet36Name, value: '39', checked: this.isSet36 },
            { name: this.isSet37Name, value: '40', checked: this.isSet37 },
            { name: this.isSet38Name, value: '41', checked: this.isSet38 },
            { name: this.isSet39Name, value: '42', checked: this.isSet39 },
            { name: this.isSet40Name, value: '43', checked: this.isSet40 }

          ];
      }, error => console.error(error))
     
      this.portalAccessText = "Provide the portal access permission to the user : " + UserName;                                                                          
    }, error => console.error(error))                                                                                                                                    

  }
  CheckUserRole(event, Id, role, UserName) {
    this.Id = Id;
    
    if (role == "admin")
    {
      this.userRoleName = "user";
      this.rolePermission = "Are you sure you want to remove " + UserName + " from admin role."; 
    }
    else
    {
      this.userRoleName = "admin";
      this.rolePermission = "Are you sure you want to add " + UserName + " as admin user.";
    }
  }
  UpdateUserRole(event) {
    
    this.usermanagement.AddRemoveAdminRole(this.Id, this.userRoleName).subscribe((data) => {
      this.userdetails = null;
      this.userRoleName = "";
      this.Id = 0;
     // alert("successfully created.");
        this.GetUserDetailList();
      }, error => console.error(error))
   
  }
}
