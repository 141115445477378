import {Component, OnInit } from '@angular/core';
import { AdjustScoreWeightsService } from '../services/adjust-score-weights.service';
import { from } from 'rxjs';
import { Router } from '@angular/router';
declare var $: any;

@Component({
	selector: 'app-adjust-score-weights',
	templateUrl: './adjust-score-weights.component.html',
	styleUrls: ['./adjust-score-weights.component.css']
})
export class AdjustScoreWeightsComponent implements OnInit {



	showModal: boolean = false;
	Id: number;
	userRoleName: string;
	rolePermission: string;

	constructor(private adjustScoreWeightsService: AdjustScoreWeightsService, private route: Router) {

	}


	scoreWeights:any = [];
	newScoreWeights:any = [];

	totalWeights:any = [];
	totalNewWeights:any = [];

	//scoreWeights.reduce((p,c)=>p+c,0)

	async ngOnInit() {

		$('[data-toggle="tooltip"]').tooltip();

		this.GetScoreWeights();

	}

	ResetScoreWeights() {
		this.newScoreWeights = JSON.parse(JSON.stringify(this.scoreWeights));
	}

	ResetScoreWeightsToDefaults() {
		this.newScoreWeights = JSON.parse(JSON.stringify(this.adjustScoreWeightsService.GetDefaultScoreWeights()));
		// this.coreWeights = JSON.parse(JSON.stringify(this.scoreWeights));
		this.UpdateTotals();



		// (res:any)=>{

		// },
		// (err)=>{
		// 	console.log('err1')
		// });

	}


	SetNewWeight(i,w) {
		if(typeof i === 'number') {
			this.newScoreWeights[i] = w;
		}
		else {
			this.newScoreWeights[this.newScoreWeights.findIndex(e=>e.ScoreName==i)] = w;
		}
	}

	UpdateTotals() {
		console.log('updating totals');
		console.log(this.scoreWeights,this.newScoreWeights);
		this.totalWeights = this.scoreWeights.reduce((p,c)=>p+c.ScoreWeight,0);
		this.totalNewWeights = this.newScoreWeights.reduce((p,c)=>p+c.ScoreWeight,0);
		console.log(this.totalWeights,this.totalNewWeights);

	}


	GetScoreWeights() {
		console.log(`getting score weights ...`);
		this.adjustScoreWeightsService.GetScoreWeights().subscribe(
		(res: any) => {
		/*.then(res=>{*/

			console.log(`scoreWeights: `,res);
			this.scoreWeights = res;
			this.newScoreWeights = JSON.parse(JSON.stringify(this.scoreWeights));

			this.ResetScoreWeights();

			this.UpdateTotals();
		},
		(err)=>{
			console.log("error section.", err);
		});

	}

	SetScoreWeights() {
		console.log(`setting score weights ...`);
		this.scoreWeights = JSON.parse(JSON.stringify(this.newScoreWeights));
		this.adjustScoreWeightsService.SetScoreWeights(this.scoreWeights).subscribe(
		(res: any) => {
			this.UpdateTotals();
			// console.log(res);
			// this.scoreWeights = res;
			// this.newScoreWeights = JSON.parse(JSON.stringify(this.scoreWeights));
		},
		(err) => {
			console.log("error section.", err);
		});
	}



}
