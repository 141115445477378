import { Component, OnInit, ViewChild, AfterViewInit, ElementRef, Input, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Paths } from '../constants';
import { OfficeMarketBuildingComponent } from '../office-market-building/office-market-building.component';
import { MarketBuildingService } from '../services/market-building.service';
import { BuildingScoresService } from '../services/building-scores.service';
import { saveAs } from "file-saver";
import html2canvas from 'html2canvas';
//import * as jsPDF from 'jspdf';
import { HttpClient, HttpHeaders, HttpClientModule } from "@angular/common/http";
import { from } from 'rxjs';
import { TenantLeaseService } from '../services/tenant-lease.service';
import { ScoreWeight } from '../core/models/score-weight';


@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnInit {

  paths: any = Paths;

  weightOptions: ScoreWeight[];
  weightSelected: ScoreWeight;

  CurrentUser: string = null;
  CurrentUserRole: string;
  CurrentUserName: string;
  IsAdmin: boolean = false;
  IsRefreshMap: boolean = false;
  IsPdfGenerate: boolean = false;
  IsPdfGenerateForTenant: boolean = false;
  IsCSVGenerate: boolean = false;
  @Input('getShowmodal') getShowmodal;
  @Output('Rmodal') Rmodal = new EventEmitter();
  @Input('bindModal') bindModal;
  @Output('Cmodal') Cmodal = new EventEmitter();
  @ViewChild('content') content: ElementRef;
  public officeMarketBuildingList: any = [];
  public tenantLeaseBuildingList: any = [];
  mapLoader: boolean = false;
  map: any;
  mapOptions: any;
    imgMap: any;
    multipleName: string;
    singleName: string;
  name = 'Angular';
  //working code......
  @ViewChild(OfficeMarketBuildingComponent) child: OfficeMarketBuildingComponent;
  //working code......

  private notify: OfficeMarketBuildingComponent;
  removemodal: boolean;
  BindResult: any;
  constructor(private tenantLeaseService: TenantLeaseService, private marketService: MarketBuildingService, private buildingScoresService: BuildingScoresService, private route: Router, public http: HttpClient) {
    //this.notify = new OfficeMarketBuildingComponent(marketService,route);
  }

  ngOnInit() {
    this.CurrentUser = sessionStorage.getItem('email');
    this.CurrentUserName = sessionStorage.getItem('fullName');
    this.CurrentUserRole = sessionStorage.getItem('role');
    if (this.CurrentUserRole === 'admin') {
      this.IsAdmin = true;
    }
    this.BindResult = this.bindModal

    this.getWeights();
  }
  
  /**
   * Check if router url contains the path
   * @param {string} path 
   * @returns bool
   */
  hasRoute(path: string) {
    return this.route.url.includes(path);
  }


  getWeights() {
    this.buildingScoresService.GetScoreWeights().subscribe(
        (res: ScoreWeight[]) => {
            this.weightOptions = res;
            this.weightSelected = this.weightOptions[0];
            this.weightChange(this.weightOptions[0]);
        },
        (err)=>{
            console.log("Score Weights Error:", err);
            alert("Could not retrieve score weights.")
    });
  }

  weightChange(weight: ScoreWeight) {
    //sessionStorage.setItem('scoreWeight', this.weightSelected.id);
    this.buildingScoresService.SetSelectedScoreWeight(weight);
  }
 
  RemoveModal() {
    this.removemodal = this.getShowmodal;
    this.removemodal = !this.removemodal;
    this.Rmodal.emit(this.removemodal);
  }


  DeActvatedModel() {
    this.RemoveModal();
    this.BindResult = this.bindModal;
    this.Cmodal.emit(this.BindResult);
  }
  Logout() {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('email');
    sessionStorage.removeItem('role');
    sessionStorage.removeItem('isActiveUser');
    sessionStorage.removeItem('name');
    sessionStorage.removeItem('showHeaderOption')
    this.route.navigate(['/login']);
  }

    GenerateMarketExcel() {
      this.mapLoader = true;

      this.marketService.MarketExcelExport().subscribe(
        (res: Blob) => {                  
          saveAs(res, 'Market_Building_Export.xlsx');
          this.mapLoader = false;
        },
        err => {
          console.log(err)
          this.mapLoader = false;
        }
      );
    }

  GenerateCompetitiveSetPDF() {
    if (this.marketService.officeMarketBuildingList.length <= 14) {
      this.mapLoader = true;

      this.marketService.CompetitiveSetPdfExport().subscribe(
        (res: Blob) => {                  
          saveAs(res, 'Competitive_Set.pdf');
          this.mapLoader = false;
        },
        err => {
          console.log(err)
          this.mapLoader = false;
        }
      );
    }
    else {
      alert(' ERROR:\n 14 Buildings Maximum for Export');
    }
  }

  GenerateRatingsExcel() {
    this.mapLoader = true;

    this.buildingScoresService.RatingsExcelExport(this.weightSelected.id).subscribe(
      (res: Blob) => {                  
        saveAs(res, 'Building_Ratings_Export.xlsx');
        this.mapLoader = false;
      },
      err => {
        console.log(err)
        this.mapLoader = false;
      }
    );
  }

  GenerateRatingsPDF() {
    if (this.buildingScoresService.buildingScoresList.length <= 10) {
      this.mapLoader = true;
      
      this.buildingScoresService.RatingsPdfExport(this.weightSelected.id).subscribe(
        (res: Blob) => {                  
          saveAs(res, 'Ratings.pdf');
          this.mapLoader = false;
        },
        err => {
          console.log(err)
          this.mapLoader = false;
        }
      );
    }
    else {
      alert(' ERROR:\n 10 Buildings Maximum for Export');
    }
  }

  DownloadCSV() {
    let fileName = 'CSV_FileExport.xlsx';
    let checkFileType = fileName.split('.').pop();
    var fileType;
    if (checkFileType == "xlsx") {
      fileType = "application/vnd.ms-excel";
    }
    this.marketService.DownloadCSVFile(fileName)
      .subscribe(success => {
        saveAs(success, fileName);
        this.IsPdfGenerate = false;
        this.IsCSVGenerate = false;
        this.mapLoader = false;
      },
        err => {
          alert("Server error while downloading file.");
        });
  }

  DownloadTenantPDFThreeRowInSinglePage() {
    
    let fileName = 'TenantPdfForThreeRowInSinglePage.pdf';//'CSV_FileExport.xlsx';
    //file type extension
    let checkFileType = fileName.split('.').pop();
    var fileType;
    if (checkFileType == "pdf") {
      fileType = "application/pdf";
    }

    this.tenantLeaseService.DownloadTenantPDFThreeRowInSinglePage(fileName)
      .subscribe(
        success => {
          saveAs(success, fileName);
          this.IsPdfGenerate = false;
          this.mapLoader = false;
        },
        err => {
          alert("Server error downloading file.");
          this.mapLoader = false;
        }
      );
    }

  GenerateLeaseCompPDF() {
    if (this.tenantLeaseService.tenantLeaseBuildingList.length <= 24) {
      this.mapLoader = true;
      
      this.tenantLeaseService.LeaseCompPdfExport().subscribe(
        (res: Blob) => {                  
          saveAs(res, 'Lease_Comp.pdf');
          this.mapLoader = false;
        },
        err => {
          console.log(err)
          this.mapLoader = false;
        }
      );
    }
    else {
      alert(' ERROR:\n 24 Leases Maximum for Export');
    }
  }

  GenerateThreeLeaseCompPDF() {
    if (this.tenantLeaseService.tenantLeaseBuildingList.length <= 24) {
      this.mapLoader = true;
      
      this.tenantLeaseService.LeaseThreeCompPdfExport().subscribe(
        (res: Blob) => {                  
          saveAs(res, 'Lease_Three_Comp.pdf');
          this.mapLoader = false;
        },
        err => {
          console.log(err)
          this.mapLoader = false;
        }
      );
    }
    else {
      alert(' ERROR:\n 24 Leases Maximum for Export');
    }
  }

  GenerateLeaseCompExcel() {
    this.mapLoader = true;

    this.tenantLeaseService.LeaseExcelExport(this.weightSelected.id).subscribe(
      (res: Blob) => {                  
        saveAs(res, 'Lease_Comp_Export.xlsx');
        this.mapLoader = false;
      },
      err => {
        console.log(err)
        this.mapLoader = false;
      }
    );
  }

  getCurrentYear() {
    return (new Date).getFullYear();
  }

}
