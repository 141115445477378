import { Component, OnInit, Output, EventEmitter  } from '@angular/core';
import { AccountService } from '../../services/account.service';
import { NgForm, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserClaims } from '../../entity/Claim.entity';
import { forEach } from '@angular/router/src/utils/collection';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  showMenu: boolean = false;
  showMsg: string = null;
  showRequestMsg: string = null;
  loginForm: FormGroup;
  submitted = false;
  requestForm: FormGroup;
  submittedRequest = false;
  claimValue = null;
  userclaims: Array<UserClaims> = [];
  isBuilding: string = null;
  isTenant: string = null;
  isExport: string = null;
  isSuccess: string = null;
  isActiveUser: boolean;
  loader: boolean = false;
  MapLoader: boolean = false;
  teamPhoto: string = 'assets/images/team-pictures.jpg';

  constructor(private service: AccountService, private router: Router, private formBuilder: FormBuilder) {
  }

  ngOnInit() {
    //validation for login controls
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    });
     //validation for request access controls
    this.requestForm = this.formBuilder.group({
      name: ['', Validators.required],
      requestPassword: ['', Validators.required],
      requestEmail: ['', [Validators.required, Validators.email]],
      requestCompany: ['', Validators.required]
    });

    this.service.GetAssetTeamPhoto().subscribe(
			(res: any) => {
        if (res != null && res != '') {
          this.teamPhoto = res;
        }				
			},
			err => {
        console.log(err);
			}
		);
  }

  get f() { return this.loginForm.controls; }
  get rf() { return this.requestForm.controls; }

  onSubmit() {
    
    this.MapLoader = true;
    this.submitted = true;
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }
    //Api calling for login
    this.service.Login(this.f.email.value, this.f.password.value).subscribe(
      (res: any) => {
        sessionStorage.setItem('id', res.id);
        sessionStorage.setItem('role', res.role);
        sessionStorage.setItem('token', res.token);
        sessionStorage.setItem('email', res.email);
        sessionStorage.setItem('name', res.name);
        sessionStorage.setItem('fullName', res.fullName);
        sessionStorage.setItem('isActiveUser', res.isActive);
        this.isActiveUser = JSON.parse(res.isActive);
       // console.log("is active or deactive usser" + this.isActiveUser);
        this.userclaims = res.listClaims;
        console.log("userclaims",JSON.stringify(this.userclaims,null,3));
        if (res.listClaims.length != 0 && res.listClaims.length != undefined) {
          for (let i = 0; i < res.listClaims.length; i++) {
            if (res.listClaims[i]['claimType'] == 'IsBuildingPortalAccess') {
              this.isBuilding = res.listClaims[i]['claimValue'];
              sessionStorage.setItem('isBuilding', this.isBuilding);
            }
            else if (res.listClaims[i]['claimType'] == 'IsTenantPortalAccess') {
              this.isTenant = res.listClaims[i]['claimValue'];
              sessionStorage.setItem('istenant', this.isTenant);
            }
            else if (res.listClaims[i]['claimType'] == 'IsReportAccess') {
              this.isExport = res.listClaims[i]['claimValue'];
              sessionStorage.setItem('isExport', this.isExport);
            }
          }
        }
        else {
          this.isBuilding = "0";
          sessionStorage.setItem('isBuilding', this.isBuilding);
          this.isTenant = "0";
          sessionStorage.setItem('istenant', this.isTenant);
          this.isExport = "0";
          sessionStorage.setItem('isExport', this.isExport);
        }
        if (this.isActiveUser) {
          this.router.navigateByUrl('/home');
          //this.router.navigate(['/home',], { queryParams: { emailId: res.email } });
        }
        else {
          this.showMsg = "This user is deactivated.";
        }
        this.MapLoader = false;
      },
      err => {
        if (err.status == 400)
          this.showMsg = "Incorrect username or password";
        this.MapLoader = false;
      }
    );
  }

  onRequestSubmit(event) {
    
    this.submittedRequest = true;
    // stop here if form is invalid
    if (this.requestForm.invalid) {
      return;
    }
    this.loader = true;
    //Api calling for request access
    this.service.RequestAccess(this.rf.name.value, this.rf.requestPassword.value, this.rf.requestEmail.value, this.rf.requestCompany.value).subscribe(
      (res: any) => {
        this.loader = false;
        if (res == "0") {
          this.showRequestMsg = "Passwords must have at least one uppercase ('A'-'Z') and User name is invalid, can only contain letters or digits. Or Email is already taken..";
        }
        else
        {
          this.showRequestMsg = "User created successfully.";
          this.requestForm.reset();
          this.submittedRequest = false;
        }
      },
      err => {
        this.loader = false;
        this.isSuccess = err.error.text.substr(err.error.text.length - 1);
        if (this.isSuccess == "0")
        {
          this.showRequestMsg = err.error.text.split("/", 1);
        }
        else
        {
          this.showRequestMsg = err.error.text.split("/", 1);
          this.requestForm.reset();
          this.submittedRequest = false;
        }
      })
  };
}

