import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { AccountService } from '../services/account.service';
import { AssertNotNull } from '@angular/compiler';
import { error } from 'util';
import { saveAs } from "file-saver";
import { Router } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {
  
  loader: boolean = false;
  reportDetailList: any;
  pdfUrl: string = "";
  private bodyText: string;
  CurrentUserRole: string;
  IsAdmin: boolean = false;
  reportIdForDelete: number = 0;
  constructor(private httpClient: HttpClient, private accountServices: AccountService, private router: Router) {
  }

  ngOnInit() {
    this.CurrentUserRole = sessionStorage.getItem('role')
    if (this.CurrentUserRole === 'admin') {
      this.IsAdmin = true;
    }
    this.bodyText = 'This text can be updated in modal 1';
    this.GetReportDetails();
  }
 
  GetReportDetails() {
    this.loader = true;
    this.accountServices.GetReportDetails().subscribe((res: any) => {
     // this.pdfUrl = res[0].title.split('/')[1];
      //res[0].title = res[0].title.split('/')[0];
      this.reportDetailList = res;
      this.loader = false;
    },
      (error: any) => {
        this.loader = false;
      });
  }


  DownLoadUploadedReportByFileName(fileName) {
    //alert(fileName);
    this.loader = true;
    fileName=fileName+".pdf";
      //file type extension
      let checkFileType = fileName.split('.').pop();
      var fileType;
      if (checkFileType == "pdf") {
        fileType = "application/pdf";
      }
    this.accountServices.DownloadUploadeReports(fileName)
        .subscribe(
          success => {
            saveAs(success, fileName);
            this.loader = false;
          },
          err => {
            this.loader = false;
            alert("Server error downloading file.");
          }
        );
  }


  DeleteUploadedReportByReportID() {
   
    this.accountServices.DeleteUploadedReportByReportID(this.reportIdForDelete)
        .subscribe(
          success => {
            this.GetReportDetails();
            this.reportIdForDelete = 0;
            alert("Deleted successfully.");
            this.loader = false;
          },
          err => {
            this.loader = false;
            alert("Server error please try later.");
          }
    );

  }
  ReportModelPopUpConfirmationByReportID(reportId) {
    this.reportIdForDelete = reportId;
    $('#myModal').modal("show");
  }

  EditReportByReportId(reportId) {
    this.accountServices.GetReportDetailsByReportId(reportId)
      .subscribe(
        success => {
          this.accountServices.reportDetails= success;
          this.router.navigateByUrl('/uploadReports');
          //this.router.navigate(['uploadReports'], success);
        },
        err => {
        }
      );
  }
}
