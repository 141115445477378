import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BuildingScoresService } from '../../services/building-scores.service';
import { ScoreWeight } from '../../core/models/score-weight';

declare var $: any;

@Component({
    selector: 'app-score-weights',
    templateUrl: './score-weights.component.html',
    styleUrls: ['./score-weights.component.css']
})

export class ScoreWeightsComponent implements OnInit {

    role = sessionStorage.getItem('role');

    // Weight List
    weights: ScoreWeight[];
    selectedWeight: ScoreWeight = {
        id: "",         
        name: "",
        default: false,
        userId: parseInt(sessionStorage.getItem('id')),
        constructionQuality: 0,
        askingRate: 0,
        commonAreaQuality: 0,
        onsiteAmenities: 0,
        sizeStories: 0,
        ageBonus: 0,
        locationProminence: 0,
        relevanceToConsumer: 0,
        locationConvenience: 0,
        locationProximity: 0,
        parkingVisitorExperience: 0,
        parkingQuantity: 0,
        parkingQuality: 0,
        updatedUtc: new Date()
    }

    get totalWeights(): number {
        return this.selectedWeight.constructionQuality 
        + this.selectedWeight.askingRate 
        + this.selectedWeight.commonAreaQuality 
        + this.selectedWeight.onsiteAmenities
        + this.selectedWeight.sizeStories
        + this.selectedWeight.ageBonus
        + this.selectedWeight.locationProminence
        + this.selectedWeight.relevanceToConsumer
        + this.selectedWeight.locationConvenience
        + this.selectedWeight.locationProximity
        + this.selectedWeight.parkingVisitorExperience
        + this.selectedWeight.parkingQuantity
        + this.selectedWeight.parkingQuality;
    }

    constructor(private scoreService: BuildingScoresService, private route: Router) { }

    ngOnInit() {
        this.weightInit();    
        this.getWeights();
    }

    getWeights() {
        this.scoreService.GetScoreWeights().subscribe(
            (res: any) => {
                this.weights = res;
            },
            (err)=>{
                console.log("Score Weights Error:", err);
                alert("Could not retrieve score weights.")
        });
    }

    weightInit() {
        this.selectedWeight.id = "";
        this.selectedWeight.name = "";
        this.selectedWeight.default = false;
        this.selectedWeight.userId = parseInt(sessionStorage.getItem('id'));
        this.selectedWeight.constructionQuality = 0;
        this.selectedWeight.askingRate = 0;
        this.selectedWeight.commonAreaQuality = 0;
        this.selectedWeight.onsiteAmenities = 0;
        this.selectedWeight.sizeStories = 0;
        this.selectedWeight.ageBonus = 0;
        this.selectedWeight.locationProminence = 0;
        this.selectedWeight.relevanceToConsumer = 0;
        this.selectedWeight.locationConvenience = 0;
        this.selectedWeight.locationProximity = 0;
        this.selectedWeight.parkingVisitorExperience = 0;
        this.selectedWeight.parkingQuantity = 0;
        this.selectedWeight.parkingQuality = 0;
    }

    canEdit(weight: ScoreWeight) {
        if (weight.default && this.role === 'admin') {
            return true;
        }

        var id = sessionStorage.getItem('id');
        if (!weight.default && weight.userId == parseInt(id)) {
            return true;
        }

        return false;
    }

    isAdmin() {
        return this.role === 'admin';
    }

    addWeight() {
        this.weightInit();
    }

    editWeight(weight: ScoreWeight) {
        //Remove change detection otherwise
        this.selectedWeight = { ...weight };;
    }

    deleteWeight(weight: ScoreWeight) {

        if (confirm("Delete weight " + weight.name + "?")) {
            this.scoreService.DeleteScoreWeight(weight.id).subscribe(
                (res: any) => {
                    this.getWeights();
                },
                (err)=>{
                    console.log("Score Weights Error:", err);
                    alert("Could not delete score weight.")
            });
        }        
    }

    saveWeight() {
        this.scoreService.CreateUpdateScoreWeight(this.selectedWeight).subscribe(
            (res: any) => {
                this.getWeights();
                this.weightInit();
            },
            (err)=>{
                console.log("Score Weights Error:", err);
                alert("Could not create score weight.")
        });
    }

}