// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: false,
    hmr:false,
  
    BaseURI_API : 'https://qa-api-sift-cbr.redrock-6cde6757.westus2.azurecontainerapps.io/api/',
    BaseURI_API_Absorption : 'https://qa-api-sift-cbr.redrock-6cde6757.westus2.azurecontainerapps.io/api/Absorption/',
    BaseURI_API_Account : 'https://qa-api-sift-cbr.redrock-6cde6757.westus2.azurecontainerapps.io/api/Account/',
    BaseURI_API_Market : 'https://qa-api-sift-cbr.redrock-6cde6757.westus2.azurecontainerapps.io/api/Market/',
    BaseURI_API_TenantLease : 'https://qa-api-sift-cbr.redrock-6cde6757.westus2.azurecontainerapps.io/api/TenantLease/',
    BaseURI_API_Score : 'https://qa-api-sift-cbr.redrock-6cde6757.westus2.azurecontainerapps.io/api/Score/',
  
    BaseURI_APIExtension : 'https://qa-api-extension-sift-cbr.redrock-6cde6757.westus2.azurecontainerapps.io/',
  
    BaseURI_Icon : 'https://orange-desert-0bdae401e.4.azurestaticapps.net',
  
    //Don't believe the following 2 need to be separated out as they are but leaving that way for now since that's how they had it originally
    BaseURI_Icon_MarketBuilding : 'https://orange-desert-0bdae401e.4.azurestaticapps.net/assets/images/',
    BaseURI_Icon_TenantLease : 'https://orange-desert-0bdae401e.4.azurestaticapps.net/assets/images/'
};  