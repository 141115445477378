import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute} from '@angular/router';
import { AccountService } from '../../services/account.service';
import { MustMatchValidater } from '../../helper/must-match-validater';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  showChangePasswordMsg: string = null;
  forgotPasswordForm: FormGroup;
  passwordSubmitted = false;
  userId: string = null;
  code: string = null;

  constructor(public fb: FormBuilder, private router: Router, private account: AccountService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      console.log(params.userId);
      console.log(params.code);
      this.userId = params.userId;
      this.code = params.code;
    })

    this.forgotPasswordForm = this.fb.group({
      'email': ['', [Validators.required, Validators.email]],
      'newPassword': ['', [Validators.required, Validators.minLength(6)]],
      'confirmNewPassword': ['', [Validators.required, Validators.minLength(6)]]
    }, {
        validator: this.comparePasswords // your validation method
      }

    );
  }

  //this method is used to compare the password.
  comparePasswords(fb: FormGroup) {
    let confirmPswrdCtrl = fb.get('confirmNewPassword');

    if (confirmPswrdCtrl.errors == null || 'passwordMismatch' in confirmPswrdCtrl.errors) {
      if (fb.get('newPassword').value != confirmPswrdCtrl.value)
        confirmPswrdCtrl.setErrors({ passwordMismatch: true });
      else
        confirmPswrdCtrl.setErrors(null);
    }
  }

  get changePasswordf() { return this.forgotPasswordForm.controls; }

  changePassword() {
    console.log("change password function called.");

    this.passwordSubmitted = true;

    // stop here if form is invalid
    if (this.forgotPasswordForm.invalid) {
      this.showChangePasswordMsg = "Confirm password does not match or Password must be at least 6 characters.";
      return;
    }
    else {
      this.account.ResetPassword(this.changePasswordf.email.value, this.code, this.changePasswordf.newPassword.value).subscribe(
        (res: any) => {
          console.log(res);
          this.showChangePasswordMsg = "Password change successfully.";
          this.forgotPasswordForm.reset();
          this.passwordSubmitted = false;
        });
    }
  }
}
