import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Paths } from '../constants';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {
  showHeader: boolean = true;
  isBuilding: string = null;
  isTenant: string = null;
  isExport: string = null;
  listclaimsType: any;
  paths: any = Paths;

  constructor(private activateRouter: ActivatedRoute, private router: Router) {
  }

  ngOnInit() {
    this.showHeader = true;
    this.isBuilding = sessionStorage.getItem('isBuilding');
    this.isTenant = sessionStorage.getItem('istenant');
    this.isExport = sessionStorage.getItem('isExport');
  }
}
