import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { AccountService } from '../../services/account.service';


@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  showMsg: string = null;
  validateEmailForm: FormGroup;
  submitted = false;
  forgotPageShow = false;

  constructor(public fb: FormBuilder, private router: Router, private account: AccountService) {
    this.validateEmailForm = fb.group({
    'email': ['', [Validators.required, Validators.email]]
    });
  }

  ngOnInit() {
  
  }

  get f() { return this.validateEmailForm.controls; }
  validateEmail() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.validateEmailForm.invalid) {
      return;
    }
    else {
      this.account.ValidateUser(this.f.email.value).subscribe(
        (res: any) => {
          console.log(res);
          if (res.email != '' && res.email != null) {
            this.forgotPageShow = true;
          //this.showMsg = "Success.";
          //this.router.navigateByUrl('/changePassword');
        }
        else
        {
          this.showMsg = "User does not exist.";
        }
      },
        err => {
          if (err.status == 400)
            this.showMsg = "User does not exist.";
          else
            this.showMsg = "User does not exist.";
        }
      );
    }
  }

}
