import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FilterPresetCreate } from '../../core/models/filter-preset-create';
import { FilterService } from '../../services/filter.service';
import { PortalType } from '../../core/portal-types';

@Component({
    selector: 'user-filter-add-modal',
    templateUrl: './user-filter-add-modal.component.html',
    styleUrls: ['./user-filter-add-modal.component.css']
})
export class UserFilterAddModalComponent {
    @Input() portalType: PortalType = PortalType.Market;
    @Input() filter: any = {};
    @Output() addFilter = new EventEmitter<string>();
    @Output() closeModal = new EventEmitter<void>();

    public name: string = "";
    public public: boolean = false;

    role = sessionStorage.getItem('role');

    constructor(private filterService: FilterService ) {
	}

    isAdmin() {
        return this.role === 'admin';
    }

    addFilterPreset() {

        var preset: FilterPresetCreate = {
            name: this.name,
            userId: parseInt(sessionStorage.getItem('id')),
            filter: this.filter,
            public: this.public
        }

        this.filterService.CreateUserFilter(preset, this.portalType).subscribe(
			(res: any) => {
                this.name = "";
				this.addFilter.emit(res);                
			},
			err => {
				console.log("Could not retrieve user filters: ", err);
			}
		);
    }

    onClose() {
        this.name = "";
        this.closeModal.emit();
    }
}