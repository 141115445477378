import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpClientModule } from "@angular/common/http";
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { FilterPresetCreate } from '../core/models/filter-preset-create';
import { FilterPreset } from '../core/models/filter-preset';
import { PortalType } from '../core/portal-types';

@Injectable({
	providedIn: 'root'
})
export class FilterService {

    BaseURI_API_Filter = environment.BaseURI_API + "Filter/";

    constructor(private http: HttpClient) { }

    GetUserFilters(portalType: PortalType) {
        return this.http.get<FilterPreset[]>(this.BaseURI_API_Filter + this.GetPortal(portalType) + '/' + sessionStorage.getItem('id'));
    }

    CreateUserFilter(preset: FilterPresetCreate, portalType: PortalType) {
        return this.http.post(this.BaseURI_API_Filter + this.GetPortal(portalType), preset);
    }

	DeleteUserFilter(id: string) {
		return this.http.delete(this.BaseURI_API_Filter + id);
	}

    private GetPortal(portalType: PortalType) {
        switch(portalType) {
            case PortalType.Market:
                return "Market";
            case PortalType.Lease:
                return "Lease";
            case PortalType.Rating:
                return "Rating";
            default:
                alert("Could not get portal type.");
        }
    }
}